import { PATH } from "../../../../config/constants";

export const links = (magasin) => [
  {
    label: "Accueil",
    to: PATH.HOME_PAGE,
  },
  {
    label: "Fournisseurs et produits",
    items: [
      {
        label: "Fournisseurs",
        to: PATH.UP_SELECTION_METIER,
      },
      {
        label: "Base articles",
        to: PATH.ARTICLES.replace(":con_code", 0),
      },
      {
        label: "MDG",
        to: PATH.MDG_PAGE,
      },
      {
        label: "Solutions logistiques",
        to: PATH.SOLUTION,
      },
      {
        label: "Aide Achat et Vente",
        to: PATH.AIDE_ACHAT_VTE,
      },
      {
        label: "Agencement & Exploitation",
        to: PATH.AE_SECTIONS,
      },
      {
        label: "Legislation",
        to: PATH.LEGISLATION,
      },
      {
        label: "Formations",
        to: PATH.FORMATION,
      },
      {
        label: "Documents & événementiel",
        to: PATH.DOCS_EVENT,
      },
    ],
  },
  {
    label: "Communication",
    items: [
      {
        label: "Communication Nationale 2025",
        to: PATH.CMNATIONALE,

      },
      {
        label: "Communication Nationale 2024",
        external: PATH.PLAN_DE_COM.replace(
          ":mag_code",
          magasin.mag_code
        ).replace(":mag_token", magasin.mag_token), //lien externe vers origine.extranet.fr
      },
      {
        label: "Bons de commande",
        to: PATH.BDC_PROSPECTUS_PAGE,
      },
      {
        label: "Communication locale",
        to: PATH.CL_OPTIONS_PAGE,
      },
      {
        label: "Programme de fidélité",
        to: PATH.FIDELITY,
      },
      {
        label: "Boutique en ligne",
        to: PATH.BOUTIQUE,
      },
      {
        label: "Plan Animation",
        to: PATH.PAM,
      },
      {
        label: "Téléchargements",
        to: PATH.TELECHARGEMENT_COM,
      },
    ],
  },
];

import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Modal from '@mui/material/Modal';
import './style.scss';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { getPaginations } from '../../../../../services/communication_apis';
import { DOMAIN } from '../../../../../config/env';
import { RESSOURCE } from '../../../../../config/constants';
import Stat from '../../../../../utils/stat';
import useAuth from '../../../../../hooks/useAuth';

/**
 * Modal that shows for a given prospectus all limite command dates by fournisseurs
 */
const BDCpaginationsModal = ({ prospectus }) => {
  const { magasin } = useAuth(); // magasins code
  const [open, setOpen] = React.useState(false); // Open/close modal
  const { fournisseursList } = useOutletContext(); // All fournisseurs list
  const [paginations, setPaginations] = useState({}); // Paginations of this prospectus

  // Open modal
  const handleOpen = () => {setOpen(true)};

  const handleClose = () => setOpen(false); // Close modal

  const handleShowProspectus = (prospectusId , libelle) => {

    window.open(`${DOMAIN.URL + RESSOURCE.PAGINATIONS_PLANOS + prospectus.psp_cprospectus}_${prospectusId}.PDF`)

    Stat.addStat({
      mag_code : magasin.mag_code,
      libelle : libelle,
      type: 1,
      prs_cprospectus : prospectus.psp_cprospectus
    })
  }

  React.useEffect(() => {
    // if (fournisseursList.length >= 1) {
    //   const fournisseursFiltered = fournisseursList.filter(
    //     (fournisseur) => {
    //       return (
    //         fournisseur.psp_cprospectus === prospectus.psp_cprospectus
    //       );
    //     }
    //   );
    //   setFournisseurs(fournisseursFiltered);
    // }
    // Get paginiations of this prospectus
    getPaginations(prospectus.psp_cprospectus).then((res) => {
      setPaginations(res.data.list);
    });
  }, [fournisseursList, prospectus.psp_cprospectus]);

  return (
    <React.Fragment key={prospectus.psp_cprospectus}>
      <button className="action-button" onClick={handleOpen}>
        Voir les paginations 

      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="pagination-modal">
          <div className="pagination-container">
            <header>
              <h3> {prospectus.psp_libelle} </h3>{' '}
              <span> Liste des catalogues </span>
            </header>
            <div className="content">
              {paginations.length ? (
                <>
                  <div className="pagination-table">
                    <TableContainer
                      sx={{ width: '90%', maxHeight: 350 }}
                    >
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">
                              Catalogue
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {paginations.map((pagination, i) => {
                            return (
                              <React.Fragment
                                key={pagination.psa_type}
                              >
                                <TableRow>
                                  <TableCell>
                                    <button
                                      className="pdf-btn"
                                      onClick={() => {
                                        handleShowProspectus(pagination.psa_num_extranet, `${pagination.ps_p_libelle} - ${pagination.psa_libelle}` )
                                      }}
                                    >
                                      {pagination.ps_p_libelle} - {pagination.psa_libelle}
                                    </button>
                                    
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </>
              ) : (
                <div className="pagination-error">
                  Aucun catalogue disponible pour ce prospectus
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default BDCpaginationsModal;

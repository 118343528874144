import React from "react";
import "./style.scss";

const RenderCell = ({ row, column }) => {
  // On verifie que la column n'a pas de cellule custom
  if (!column.Cell) {
    // On affiche la valeur de la cellule en fonction de la colonne
    return <>{row[column.accessor]}</>;
  }
  // On retourne la cellule custom
  return <>{column.Cell(row)}</>;
};

const TitleRowTable = ({ columns, data, titleRow, dataRow, header = true }) => {
  if (!columns) return null;
  return (
    <div className="title-row-table-wrapper">
      <table className="title-row-table">
        <thead>
          <tr className="head-row">
            {header &&
              columns.map((column, index) => (
                <th key={index}>{column.header}</th>
              ))}
          </tr>
        </thead>
        <tbody>
          {data.map((rows) => (
            <>
              {rows[dataRow.accessor] && rows[dataRow.accessor].length > 0 && (
                <>
                  <tr className="title-row">
                    <td colSpan={`${columns.length}`}>
                      {rows[titleRow.accessor]}
                    </td>
                  </tr>
                  {rows[dataRow.accessor].map((row, cellIndex) => (
                    <tr className="row" key={cellIndex}>
                      {columns.map((column, columnIndex) => (
                        <td key={columnIndex}>
                          <RenderCell row={row} column={column} />
                        </td>
                      ))}
                    </tr>
                  ))}
                </>
              )}
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TitleRowTable;

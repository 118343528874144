import React, { createContext, useContext, useState, useEffect } from "react";
import useAuth from "../../../../hooks/useAuth";
import {
  getPanierUser,
  storePanier,
  deletePanier,
} from "../../../../services/boutique_api";
export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const { magasin } = useAuth();
  const [cartItems, setCartItems] = useState([]);
  const mag_code = magasin.mag_code;

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const items = await getPanierUser(mag_code);
        setCartItems(items);
      } catch (error) {
        console.error("Erreur lors de la récupération du panier :", error);
      }
    };

    if (mag_code) {
      fetchCartItems();
    }
  }, [mag_code]);


  const addOrUpdateItem = async (item) => {
    try {
      const itemWithMagCode = { ...item, mag_code };
      await storePanier(itemWithMagCode);
      // Refetcher le panier entier
      const items = await getPanierUser(mag_code);
      setCartItems(items);
    } catch (error) {
      console.error("Erreur lors de l'ajout ou de la mise à jour du panier :", error);
    }
  };

  const removeFromCart = async (cartItemId) => {
    setCartItems((prevItems) => prevItems.filter((item) => item.id !== cartItemId));

    try {
      await deletePanier(cartItemId, mag_code);
    } catch (error) {
      console.error("Erreur lors de la suppression de l'article du panier :", error);
      const items = await getPanierUser(mag_code);
      setCartItems(items);
    }
  };

  const updateQuantity = async (cartItemId, amount) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.id === cartItemId
          ? { ...item, quantite: Math.max(1, item.quantite + amount) }
          : item
      )
    );
    try {
      const item = cartItems.find((item) => item.id === cartItemId);
      if (item) {
        const newQuantity = Math.max(1, item.quantite + amount);
        const updatedItemData = {
          cart_item_id: cartItemId,
          produit_id: item.produit_id,
          quantite: newQuantity,
          taille_id: item.taille_id,
          couleur_id: item.couleur_id,
          logo_id: item.logo_id,
          mag_code,
        };
        await storePanier(updatedItemData);
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la quantité :", error);
      const items = await getPanierUser(mag_code);
      setCartItems(items);
    }
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        setCartItems,
        addOrUpdateItem,
        removeFromCart,
        updateQuantity,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  return useContext(CartContext);
};

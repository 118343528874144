import { PATH } from "../../../config/constants";
import { DOMAIN } from "../../../config/env";
import { RESSOURCE } from "../../../config/constants";
import BasketIcon from "../../ui/icons/BasketIcon";
import ShopcartIcon from "../../ui/icons/ShopcartIcon";
import OrderIcon from "../../ui/icons/OrderIcon";
import { useCart } from "../../../screens/communication/Boutique/Panier/CartContext";

const useTotalItems = () => {
  const { cartItems } = useCart();
  return cartItems.reduce((acc, item) => acc + item.quantite, 0);
};

const useBasketLink = () => {
  const totalItems = useTotalItems();
  return {
    to: PATH.BOUTIQUE_PANIER,
    libelle: "MON PANIER",
    type: "filled",
    icon: (
      <div className="basket-icon-container">
        <BasketIcon height={50} width={50} />
        {totalItems > 0 && (
          <span className="basket-quantity">{totalItems}</span>
        )}
      </div>
    ),
  };
};

export const useLinks = () => [
  {
    to: PATH.BOUTIQUE,
    libelle: "BOUTIQUE EN LIGNE",
    type: "filled",
    icon: <ShopcartIcon height={50} width={50} />,
  },
  useBasketLink(),
  {
    to: PATH.BOUTIQUE_COMMANDE,
    libelle: "MES COMMANDES",
    type: "filled",
    icon: <OrderIcon height={50} width={50} />,
  },
  {
    to: PATH.BOUTIQUE_GUIDE_TAILLE,
    libelle: "GUIDE DES TAILLES",
    type: "outlined",
  },
  {
    href:
      DOMAIN.URL +
      "/" +
      RESSOURCE.BOUTIQUE_AUTRE +
      "/" +
      "CGV IS communication 2018.pdf",
    libelle: "CONDITIONS GENERAL DE VENTE",
    type: "outlined",
  },
];

export const useColoredLinks = () => {
  const linksList = useLinks();
  return linksList.map((link, index) => {
    const colorClass =
      index % 2 === 0 ? "filled-link--blue" : "filled-link--green";
    return {
      ...link,
      class: `${link.class} ${colorClass}`,
    };
  });
};

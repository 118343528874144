import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { DOMAIN } from "../../../../config/env";
import { RESSOURCE } from "../../../../config/constants";
import { getProduitById } from "../../../../services/boutique_api";
import ComingSoon from "./ComingSoon.jpg";
import { useCart } from "../Panier/CartContext";
import "./style.scss";
import ReturnBtn from "../../../../components/ui/button/ReturnBtn";

const FicheProduit = () => {
  const { idProduit } = useParams();
  const [produit, setProduit] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [selectedTaille, setSelectedTaille] = useState({
    code: 0,
    libelle: null,
  });
  const [selectedCouleur, setSelectedCouleur] = useState({
    code: 0,
    libelle: null,
  });
  const [selectedLogo, setSelectedLogo] = useState({
    code: 0,
    libelle: null,
  });

  const [selectedQuantite, setSelectedQuantite] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const { addOrUpdateItem } = useCart();
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    async function fetchRayons () {
      const data = await getProduitById(idProduit);
      if (data.images && data.images.length > 0) {
        data.images.sort(
          (a, b) => (b.img_principale ? 1 : 0) - (a.img_principale ? 1 : 0)
        );
      }

      setProduit(data);
      if (data.tailles && data.tailles.length === 1) {
        setSelectedTaille({
          code: data.tailles[0].ta_code,
          libelle: data.tailles[0].ta_libelle,
        });
      }
      if (data.couleurs && data.couleurs.length === 1) {
        setSelectedCouleur({
          code: data.couleurs[0].cou_code,
          libelle: data.couleurs[0].cou_libelle,
        });
      }
      if (data.tailles && data.tailles.length === 1) {
        setSelectedTaille({
          code: data.tailles[0].ta_code,
          libelle: data.tailles[0].ta_libelle,
        });
      }
      if (data.couleurs && data.couleurs.length === 1) {
        setSelectedCouleur({
          code: data.couleurs[0].cou_code,
          libelle: data.couleurs[0].cou_libelle,
        });
      }
      if (data.logos && data.logos.length === 1) {
        setSelectedLogo({
          code: data.logos[0].lo_code,
          libelle: data.logos[0].lo_libelle,
        });
      }
    }
    fetchRayons();
  }, [idProduit]);

  const addToCart = () => {
    if (
      (produit.tailles &&
        produit.tailles.length > 1 &&
        !selectedTaille.libelle) ||
      (produit.couleurs &&
        produit.couleurs.length > 1 &&
        !selectedCouleur.libelle) ||
      (produit.logos && produit.logos.length > 1 && !selectedLogo.libelle)
    ) {
      setErrorMessage(
        "Veuillez sélectionner la taille, la couleur et/ou le logo avant d'ajouter au panier."
      );
      return;
    }

    const newItem = {
      produit_id: produit.pr_code,
      quantite: selectedQuantite,
      taille_id: selectedTaille.code || 0,
      couleur_id: selectedCouleur.code || 0,
      logo_id: selectedLogo.code || 0,
    };

    addOrUpdateItem(newItem);

    setErrorMessage("");
    setConfirmationMessage("Produit ajouté au panier !");
    setShowModal(true);
  };

  const CartModal = ({ onClose }) => (
    <div className="modal">
      <div className="modal-content">
        <h2>Produit ajouté au panier</h2>
        <p>Voulez-vous continuer vos achats ou aller au panier ?</p>
        <div className="modal-button-container">
          <button className="button gris" onClick={onClose}>
            Continuer les achats
          </button>
          <Link to="/boutique/panier" className="button">
            Aller au panier
          </Link>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    if (confirmationMessage) {
      const timer = setTimeout(() => {
        setConfirmationMessage("");
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [confirmationMessage]);

  return (
    <div>
      <div className="titre-panier">Fiche Produit</div>
      <ReturnBtn />
      {showModal && <CartModal onClose={() => setShowModal(false)} />}

      <div className="fiche-produit-card">
        <div className="fiche-image-container">
          {produit.pr_pvht_remise > 0 && (
            <span className="promo-label">Promo</span>
          )}
          {produit.images && produit.images.length > 0 ? (
            <>
              <img
                src={
                  DOMAIN.URL +
                  "/" +
                  RESSOURCE.BOUTIQUE +
                  "/" +
                  produit.images[currentImageIndex].img_image
                }
                alt={produit.pr_designation}
              />

              <div className="thumbnail-container">
                {produit.images.map((image, index) => (
                  <img
                    key={index}
                    src={
                      DOMAIN.URL +
                      "/" +
                      RESSOURCE.BOUTIQUE +
                      "/" +
                      image.img_image
                    }
                    alt={produit.pr_designation}
                    onClick={() => setCurrentImageIndex(index)}
                    className={
                      index === currentImageIndex ? "active-thumbnail" : ""
                    }
                  />
                ))}
              </div>
            </>
          ) : (
            <img src={ComingSoon} alt={produit.pr_designation} />
          )}
        </div>
        <div className="fiche-produit-info">
          <h2>{produit.pr_designation}</h2>

          {produit.pr_pvht_remise ? (
            <>
              <p className="old-price">{produit.pr_pvht} €</p>
              <p className="promo-price">
                {produit.prix_avec_remise.toFixed(2)} €
              </p>
            </>
          ) : (
            <p className="price">{produit.pr_pvht} €</p>
          )}

          <p className="condition">{produit.pr_condi}</p>
          <p className="shipping">{produit.pr_fdp}</p>

          <div className="select-container">
            {produit.tailles && produit.tailles.length > 0 && (
              <div className="custom-select">
                <select
                  value={selectedTaille.code}
                  onChange={(e) => {
                    const taille = produit.tailles.find(
                      (t) => t.ta_code.toString() === e.target.value
                    );
                    setSelectedTaille({
                      code: taille.ta_code,
                      libelle: taille.ta_libelle,
                    });
                  }}
                  disabled={produit.tailles && produit.tailles.length === 1}
                >
                  <option value="">Taille</option>
                  {produit.tailles &&
                    produit.tailles.map((taille, index) => (
                      <option key={index} value={taille.ta_code}>
                        {taille.ta_libelle}
                      </option>
                    ))}
                </select>
              </div>
            )}
            {produit.couleurs && produit.couleurs.length > 0 && (
              <div className="custom-select">
                <select
                  value={selectedCouleur.code}
                  onChange={(e) => {
                    const couleur = produit.couleurs.find(
                      (c) => c.cou_code.toString() === e.target.value
                    );
                    setSelectedCouleur({
                      code: couleur.cou_code,
                      libelle: couleur.cou_libelle,
                    });
                  }}
                  disabled={produit.couleurs && produit.couleurs.length === 1}
                >
                  <option value="">Couleur</option>
                  {produit.couleurs &&
                    produit.couleurs.map((couleur, index) => (
                      <option key={index} value={couleur.cou_code}>
                        {couleur.cou_libelle}
                      </option>
                    ))}
                </select>
              </div>
            )}
            {produit.logos && produit.logos.length > 0 && (
              <div className="custom-select">
                <select
                  value={selectedLogo.code}
                  onChange={(e) => {
                    const logo = produit.logos.find(
                      (l) => l.lo_code.toString() === e.target.value
                    );
                    setSelectedLogo({
                      code: logo.lo_code,
                      libelle: logo.lo_libelle,
                    });
                  }}
                  disabled={produit.logos && produit.logos.length === 1}
                >
                  <option value="">Logo</option>
                  {produit.logos &&
                    produit.logos.map((logo, index) => (
                      <option key={index} value={logo.lo_code}>
                        {logo.lo_libelle}
                      </option>
                    ))}
                </select>
              </div>
            )}
          </div>

          <div className="quantity-container">
            <label>Quantité: </label>
            <input
              type="number"
              value={selectedQuantite}
              onChange={(e) => setSelectedQuantite(Number(e.target.value))}
              min="1"
              defaultValue="1"
            />
          </div>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          {confirmationMessage && (
            <div className="confirmation-message">{confirmationMessage}</div>
          )}
          <button className="add-to-cart-btn" onClick={addToCart}>
            Ajouter au panier
          </button>
          <h3>Description</h3>
          <p className="description">{produit.pr_description}</p>
        </div>
      </div>
    </div>
  );
};
export default FicheProduit;

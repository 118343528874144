import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getProduitByRayon,
  getAllProduits,
} from "../../../../services/boutique_api";
import ProduitCard from "../../../../components/ui/cards/Boutique/ProduitCard";
import "./style.scss";
import ReturnBtn from "../../../../components/ui/button/ReturnBtn";
import useAuth from "../../../../hooks/useAuth";

const BoutiqueRayon = () => {
  const { magasin } = useAuth(); // magasins code
  const { rayon } = useParams();
  const [produits, setProduits] = useState([]);

  useEffect(() => {
    async function fetchRayons() {
      let data;
      if (rayon === "all") {
        data = await getAllProduits(magasin.mag_code);
      } else {
        data = await getProduitByRayon(rayon, magasin.mag_code);
      }

      setProduits(data);
    }

    fetchRayons();
  }, [rayon]);

  return (
    <div>
      <div className="produit-titre">PRODUIT</div>
      <ReturnBtn />
      <div className="produits-container">
        {produits.map((produit) => (
          <ProduitCard
            key={produit.pr_code}
            produit={produit}
            rayonCode={rayon}
          />
        ))}
      </div>
    </div>
  );
};

export default BoutiqueRayon;

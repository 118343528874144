import React, { useState } from 'react';
import SalonLayout from '../../../../components/layout/SalonLayout';
import Input from '../../../../components/ui/input/Input';
import useAuth from '../../../../hooks/useAuth';
import './style.scss';
import SharpButton from '../../../../components/ui/button/SharpButton';
import Checkbox from '../../../../components/ui/input/Checkbox';
import Textarea from '../../../../components/ui/input/Textarea';
import validation from './validation';
import { PATH } from '../../../../config/constants';
import { useNavigate } from 'react-router-dom';
import { inscriptionSalon } from '../../../../services/salon-api';
import { DOMAIN } from '../../../../config/env';
import SalonInscriptionModal from '../../../../components/ui/modal/communication/SalonInscriptionModal';

const InscriptionSalon = () => {
  const { magasin, setAuthData, authData } = useAuth();
  const [errors, setErrors] = useState({}); // Error bag for form
  const [isValidForm, setIsValidForm] = useState(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    mag_code: magasin.mag_code,
    mag_date: '',
    mag_nom: magasin.mag_nom, //required
    mag_contact: '', // required
    mag_ville: magasin.mag_ville,
    mag_tel: '', // required
    mag_mail: '', //required
    mag_reponse: '1',
    mag_p1_nom: '',
    mag_p1_prenom: '',
    mag_p1_repas1: 0,
    mag_p1_repas2: 0,
    mag_p1_repas3: 0,
    mag_p2_nom: '',
    mag_p2_prenom: '',
    mag_p2_repas1: 0,
    mag_p2_repas2: 0,
    mag_p2_repas3: 0,
    mag_p3_nom: '',
    mag_p3_prenom: '',
    mag_p3_repas1: 0,
    mag_p3_repas2: 0,
    mag_p3_repas3: 0,
    mag_p4_nom: '',
    mag_p4_prenom: '',
    mag_p4_repas1: 0,
    mag_p4_repas2: 0,
    mag_p4_repas3: 0,
    mag_p5_nom: '',
    mag_p5_prenom: '',
    mag_p5_repas1: 0,
    mag_p5_repas2: 0,
    mag_p5_repas3: 0,
    mag_p6_nom: '',
    mag_p6_prenom: '',
    mag_p6_repas1: 0,
    mag_p6_repas2: 0,
    mag_p6_repas3: 0,
    mag_p7_nom: '',
    mag_p7_prenom: '',
    mag_p7_repas1: 0,
    mag_p7_repas2: 0,
    mag_p7_repas3: 0,
    mag_p8_nom: '',
    mag_p8_prenom: '',
    mag_p8_repas1: 0,
    mag_p8_repas2: 0,
    mag_p8_repas3: 0,
    mag_raison_1: 0,
    mag_raison_2: 0,
    mag_raison_3: 0,
    mag_raison_4: 0,
    mag_commentaire: '',
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData({ ...formData, [name]: newValue });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Ajoutez ceci pour un défilement en douceur
    });
  };

  const handleSubmit = (event) => {
    // Validation check for inputs
    const validate = async () => {
      const errorBag = await validation(formData);
      setErrors(errorBag);
      const hasErrors = Object.keys(errorBag).length;
      setIsValidForm(!hasErrors);
      if (hasErrors) {
        scrollToTop();
        throw new Error('Champs invalides');
      }
    };
    try {
      event.preventDefault();
      setIsValidForm(false);
      validate();
    } catch (err) {
      console.log(err);
    }
  };

  const handleInscription = async () => {
    await inscriptionSalon({
      ...formData,
    });
    setAuthData({
      ...authData,
      magasin: { ...magasin, mag_salon: 2 },
    });
    navigate(PATH.HOME_PAGE);
  };

  const generateRows = () => {
    const rows = [];

    for (let i = 1; i <= 8; i++) {
      rows.push(
        <tr key={i}>
          <td>{i}</td>
          <td>
            <Input
              type="text"
              name={`mag_p${i}_nom`}
              value={formData[`mag_p${i}_nom`]}
              onChange={handleChange}
              placeholder="Nom"
            />
          </td>
          <td>
            <Input
              type="text"
              name={`mag_p${i}_prenom`}
              value={formData[`mag_p${i}_prenom`]}
              onChange={handleChange}
              placeholder="Prénom"
            />
          </td>
          <td className="checkbox-cell">
            <span>
              <Checkbox
                name={`mag_p${i}_repas1`}
                checked={formData[`mag_p${i}_repas1`]}
                onChange={handleChange}
              />
            </span>
          </td>
          <td className="checkbox-cell">
            <span>
              <Checkbox
                name={`mag_p${i}_repas2`}
                checked={formData[`mag_p${i}_repas2`]}
                onChange={handleChange}
              />
            </span>
          </td>
          <td className="checkbox-cell">
            <span>
              <Checkbox
                name={`mag_p${i}_repas3`}
                checked={formData[`mag_p${i}_repas3`]}
                onChange={handleChange}
              />
            </span>
          </td>
        </tr>
      );
    }

    return rows;
  };

  return (
    <SalonLayout>
      <form
        className="salon-formulaire"
        onSubmit={(event) => handleSubmit(event)}
      >
        <div className="contact">
          <h3 className="title">Mon inscription</h3>
          <div className="contact-inputs">
            <div>
              <Input
                type="text"
                name="mag_nom"
                label="Nom du magasin"
                value={formData.mag_nom}
                onChange={handleChange}
                error={errors.mag_nom}
              />
              <Input
                type="text"
                name="mag_ville"
                label="Ville"
                value={formData.mag_ville}
                onChange={handleChange}
                error={errors.mag_ville}
              />
              <Input
                type="text"
                name="mag_mail"
                label="Mail"
                value={formData.mag_mail}
                onChange={handleChange}
                error={errors.mag_mail}
              />
            </div>
            <div>
              <Input
                type="text"
                name="mag_tel"
                label="Tel"
                value={formData.mag_tel}
                onChange={handleChange}
                error={errors.mag_tel}
              />
              <Input
                type="text"
                name="mag_contact"
                label="Contact"
                value={formData.mag_contact}
                onChange={handleChange}
                error={errors.mag_contact}
              />
            </div>
          </div>
        </div>
        <div className="participation">
          <div className="radio-wrap">
            <span>
              Je serai présent au Salon Inédis, les 8 et/ou 9 novembre
              2023
            </span>
            <div className="radio">
              <div className="radio-option">
                <input
                  type="radio"
                  value="1"
                  name={'mag_reponse'}
                  checked={formData.mag_reponse === '1'}
                  onChange={handleChange}
                />
                oui
              </div>
              <div className="radio-option">
                <input
                  type="radio"
                  value="0"
                  name={'mag_reponse'}
                  checked={formData.mag_reponse === '0'}
                  onChange={handleChange}
                />
                non
              </div>
            </div>
          </div>
          {formData.mag_reponse === '1' ? (
            <>
              <div className="info-participation">
                <h4> Participant(s) </h4>
                <p>
                  Dans le cadre de la politique RSE d’Inédis et afin
                  de lutter contre le gâchis alimentaire, tout repas
                  réservé qui ne sera pas consommé sera facturé à
                  hauteur de 39 € HT pour les buffets et 59 € HT pour
                  le dîner.
                </p>
                <table className="table-participants">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nom</th>
                      <th>Prénom</th>
                      <th className="checkbox-cell">Buffet 08/11</th>
                      <th className="checkbox-cell">Diner 08/11</th>
                      <th className="checkbox-cell">Buffet 09/11</th>
                    </tr>
                  </thead>
                  <tbody>{generateRows()}</tbody>
                </table>
              </div>
              <div className="submit">
                <SalonInscriptionModal
                  isValidForm={isValidForm}
                  onValidation={handleInscription}
                  email={formData.mag_mail}
                />
              </div>
            </>
          ) : (
            <>
              <div className="info-non-participation">
                <p>Quels sont les raisons de votre non venue ?</p>
                <table>
                  <tbody>
                    <tr>
                      <td>Familles peu représentatives en magasin</td>
                      <td>
                        <Checkbox
                          value={formData.mag_raison_1}
                          name={'mag_raison_1'}
                          checked={formData.mag_raison_1}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td> Ne voit pas l'intéret du salon</td>
                      <td>
                        <Checkbox
                          value={formData.mag_raison_2}
                          name={'mag_raison_2'}
                          checked={formData.mag_raison_2}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Manque de personnel en magasin</td>
                      <td>
                        <Checkbox
                          value={formData.mag_raison_3}
                          name={'mag_raison_3'}
                          checked={formData.mag_raison_3}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Trop loin ou trop coûteux</td>
                      <td>
                        <Checkbox
                          value={formData.mag_raison_4}
                          name={'mag_raison_4'}
                          checked={formData.mag_raison_4}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div>
                  Autre (préciser)
                  <Textarea
                    rows="6"
                    maxLength="255"
                    placeholder="Votre commentaire ..."
                    name="mag_commentaire"
                    value={formData.mag_commentaire}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="submit">
                <SharpButton type="submit" libelle="Valider" />
              </div>
            </>
          )}
        </div>
      </form>

      <div className="dossier-salon-info">
        <p className="info">
          Vos badges seront envoyé par mail, 15 jours avant le début
          du salon. <br /> Pour la marche à suivre, merci de vous
          réfèrer au dossier Salon à télécharger
        </p>
        <a
          href={`${DOMAIN.URL}_fichier/_salon/dossier salon.pdf`}
          target="_blank"
          rel="noreferrer"
        >
          <SharpButton
            libelle="Télécharger le dossier salon >"
            color="color-secondary"
          />
        </a>
      </div>
    </SalonLayout>
  );
};

export default InscriptionSalon;

import React from "react";
import { formatPrix } from "../../../../utils/dates";
import { FaEye } from "react-icons/fa";

const ConfirmedOperations = ({ data, onViewDetails }) => {
  return (
    <div className="mes-engagements-container">
      <h2 className="table-title">Plan de communication validé</h2>
      <table className="engagements-table">
        <thead>
          <tr>
            <th>MOIS</th>
            <th>OPÉRATION</th>
            <th>DATES DE VALIDITE</th>
            <th>TOTAL</th>
            <th>VOIR</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.id}>
              <td>{row.mois}</td>
              <td>{row.operation}</td>
              <td>{row.datesValidité}</td>
              <td className="total-column">{formatPrix(row.cout)}</td>
              <td className="view-column">
                <FaEye
                  onClick={() => onViewDetails(row.id)}
                  style={{ cursor: "pointer" }}
                  title="Voir les détails de l'opération"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ConfirmedOperations;

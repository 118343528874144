import React from "react";
import { Link } from "react-router-dom";
import { DOMAIN } from "../../../../../config/env";
import { RESSOURCE } from "../../../../../config/constants";
import ComingSoon from "./ComingSoon.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.scss";

const ProduitCard = ({ produit }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const sortedImages = produit.images.sort(
    (a, b) => b.img_principale - a.img_principale
  );
  console.log(produit);
  return (
    <div className="produit-card">
      <div className="image-container">
        {produit.pr_pvht_remise !== 0 && (
          <span className="promo-label">Promo</span>
        )}
        {produit.images && produit.images.length > 0 ? (
          <Slider {...settings}>
            {sortedImages.map((image, index) => (
              <div key={index}>
                <Link to={`/boutique/ficheproduit/${produit.pr_code}`}>
                  <img
                    src={
                      DOMAIN.URL +
                      "/" +
                      RESSOURCE.BOUTIQUE +
                      "/" +
                      image.img_image
                    }
                    alt={produit.pr_designation}
                  />
                </Link>
              </div>
            ))}
          </Slider>
        ) : (
          <img src={ComingSoon} alt={produit.pr_designation} />
        )}
      </div>
      <div className="produit-info">
        <h2>{produit.pr_designation}</h2>

        {produit.pr_pvht_remise ? (
          <>
            <p className="old-price">{produit.pr_pvht} €</p>
            <p className="promo-price">
              {produit.prix_avec_remise.toFixed(2)} €
            </p>
          </>
        ) : (
          <p className="price">{produit.pr_pvht} €</p>
        )}

        <p className="condition">{produit.pr_condi}</p>
        <p className="shipping">{produit.pr_fdp}</p>
        <Link
          to={`/boutique/ficheproduit/${produit.pr_code}`}
          className="detail-btn"
        >
          Voir le détail
        </Link>
      </div>
    </div>
  );
};

export default ProduitCard;

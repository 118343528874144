import { PATH } from "../../../config/constants";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";

export const useLinks = () => [
  {
    to: PATH.ENGACOM,
    libelle: "PLAN DE COMMUNICATION",
    type: "filled",
    icon: <FormatListBulletedIcon fontSize={"large"} />,
  },
  {
    to: PATH.MES_ENGAGEMENTS,
    libelle: "MES ENGAGEMENTS",
    type: "filled",
    icon: <GroupAddIcon fontSize={"large"} />,
  },
  {
    to: PATH.REPIQUAGE,
    libelle: "REPIQUAGE INDIVIDUEL",
    type: "filled",
    icon: <PersonOutlineIcon fontSize={"large"} />,
  },
  {
    to: PATH.ZONE_DISTRIBUTION,
    libelle: "ZONE(S) DE DISTRIBUTION MEDIAPOST",
    type: "filled",
    icon: <LocalShippingIcon fontSize={"large"} />,
  },
  {
    to: PATH.BDC_PROSPECTUS_PAGE,
    libelle: "BONS DE COMMANDE PRODUITS",
    type: "filled",
    icon: <TextSnippetIcon fontSize={"large"} />,
  },
];

export const useColoredLinks = () => {
  const linksList = useLinks();
  return linksList.map((link, index) => {
    const colorClass =
      index % 2 === 0 ? "filled-link--blue" : "filled-link--green";
    return {
      ...link,
      class: `${link.class} ${colorClass}`,
    };
  });
};

import React, { useState } from 'react';

import useAuth from '../../../../hooks/useAuth';

import Hamburger from './hamburger';
import NavLink from './NavLink';
import './style.scss';
import { PATH, ROLES } from '../../../../config/constants';
import { links } from './links';

// const { Link } = require("react-router-dom");

const NavigationBar = () => {
  const { user, authed, onLogout } = useAuth();
  const { magasin } = useAuth();
  const [selectedLinkIndex, setSelectedLinkIndex] = useState(-1);
  const [openDropdown, setOpenDropdown] = useState(-1);
  const [selectedUserLinkIndex, setSelectedUserLinkIndex] =
    useState(-1);

  function capitalizeWords(string) {
    return string
      .split(' ')
      .map(
        (word) =>
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join(' ');
  }

  const manageLinks = [
    {
      label: capitalizeWords(
        magasin.mag_nom + ' - ' + magasin.mag_ville
      ),
      items: [
        { label: ' Mon profil', to: PATH.PROFILE },
        { label: 'Deconnexion', onClick: onLogout },
      ],
    },
  ];

  if (user.roles[0].id !== ROLES.MAGASIN) {
    manageLinks[0].items.unshift({
      label: 'Changement de magasin',
      to: PATH.SELECT_MAGASIN,
    });
  }

  const handleClick = (event) => {
    const { target } = event;
    if (!target.closest('.dropdown')) {
      // setSelectedLinkIndex(-1);
      setSelectedUserLinkIndex(-1);
      setOpenDropdown(-1);
    }
  };

  React.useEffect(() => {
    window.addEventListener('click', handleClick);
    return () => window.removeEventListener('click', handleClick);
  }, []);

  const handleSelection = (index) => {
    setSelectedLinkIndex(index);
    setOpenDropdown(index);
    console.log(selectedLinkIndex, openDropdown);
  };

  return (
    <>
      <nav className="nav">
        <div className="nav__burger">
          <Hamburger />
        </div>
        <div className="nav__links">
          <ul className="dropdown">
            {links(magasin).map((link, index) => (
              <NavLink
                key={link.label}
                link={link}
                selected={selectedLinkIndex === index}
                openDropdown={openDropdown === index}
                visible={link.visible}
                onClick={() => handleSelection(index)}
              />
            ))}
          </ul>
        </div>

        <div className="nav__actions">
          <ul className="dropdown">
            {authed && (
              <>
                {manageLinks.map((link, index) => (
                  <NavLink
                    key={link.label}
                    link={link}
                    selected={selectedUserLinkIndex === index}
                    onClick={() => setSelectedUserLinkIndex(index)}
                  />
                ))}
              </>
            )}
          </ul>
        </div>
      </nav>
    </>
  );
};
export default NavigationBar;

import React from "react";

const ShopcartIcon = ({ height = 20, width = 20 }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox="0 0 900.000000 900.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      {" "}
      <g
        transform="translate(0.000000,900.000000) scale(0.100000,-0.100000)"
        fill="#ffffff"
        stroke="none"
      >
        {" "}
        <path d="M314 8609 c-34 -5 -83 -19 -110 -30 -64 -29 -144 -110 -178 -183 -26 -54 -27 -66 -24 -165 2 -86 7 -115 26 -153 47 -95 141 -173 241 -201 20 -5 218 -12 441 -16 393 -6 406 -7 446 -28 66 -35 121 -121 154 -238 4 -16 13 -43 19 -60 24 -65 63 -182 71 -210 4 -16 13 -43 19 -60 20 -55 92 -269 101 -300 4 -16 24 -77 45 -135 20 -58 40 -118 45 -135 4 -16 13 -43 19 -60 6 -16 27 -77 46 -135 19 -58 39 -118 45 -135 6 -16 26 -77 45 -135 42 -129 54 -165 70 -210 7 -19 22 -64 34 -100 11 -36 25 -78 31 -95 6 -16 33 -97 60 -180 27 -82 54 -163 60 -180 29 -84 92 -274 100 -300 4 -16 13 -43 19 -60 20 -55 92 -269 101 -300 4 -16 24 -77 45 -135 20 -58 40 -118 45 -135 4 -16 13 -43 19 -60 54 -145 187 -565 197 -625 26 -144 15 -290 -37 -485 -12 -44 -24 -96 -29 -115 -4 -19 -12 -53 -18 -75 -37 -137 -45 -182 -49 -290 -8 -190 16 -314 87 -461 156 -322 434 -518 785 -553 153 -15 4512 -15 4621 0 155 21 258 104 313 250 53 139 1 314 -122 411 -56 45 -107 66 -192 78 -44 6 -909 10 -2325 10 -1556 0 -2267 3 -2293 11 -54 15 -110 76 -125 135 -10 40 -10 61 2 114 19 89 36 157 52 209 8 25 14 52 14 62 0 50 65 146 125 185 28 18 87 19 2110 25 1379 3 2093 9 2120 16 74 19 150 71 200 138 43 57 53 77 76 152 12 38 27 84 34 103 8 19 28 80 46 135 17 55 35 110 40 123 5 12 31 91 59 175 27 83 54 166 60 182 6 17 26 77 45 135 19 58 39 119 45 135 6 17 46 138 90 270 44 132 84 254 91 270 6 17 15 44 19 60 8 27 73 223 100 300 6 17 27 80 46 140 20 61 42 126 49 145 27 75 37 106 45 135 7 26 24 76 70 210 6 17 33 98 60 180 27 83 54 164 60 180 32 87 51 183 46 224 -7 48 -29 118 -48 153 -43 77 -142 156 -228 182 -24 7 -1001 12 -3130 16 l-3095 5 -50 31 c-27 17 -62 48 -76 70 -24 36 -68 145 -89 219 -4 17 -24 77 -45 135 -20 58 -40 119 -45 135 -4 17 -13 44 -19 60 -6 17 -27 77 -46 135 -19 58 -39 119 -45 135 -6 17 -31 93 -56 170 -57 175 -85 233 -141 288 -60 60 -132 94 -226 106 -95 13 -1124 13 -1213 0z" />{" "}
        <path d="M2820 1469 c-135 -38 -209 -73 -290 -137 -136 -108 -228 -253 -269 -423 -54 -225 3 -468 151 -644 49 -59 141 -141 173 -156 11 -5 39 -20 62 -33 99 -56 189 -76 343 -76 148 0 219 14 328 67 167 81 309 234 368 397 71 197 72 357 0 552 -60 165 -200 315 -368 397 -153 74 -355 97 -498 56z" />{" "}
        <path d="M7320 1469 c-135 -38 -209 -73 -290 -137 -136 -108 -228 -253 -269 -423 -54 -225 3 -468 151 -644 49 -59 141 -141 173 -156 11 -5 39 -20 62 -33 99 -56 189 -76 343 -76 148 0 219 14 328 67 167 81 309 234 368 397 59 162 69 296 33 443 -11 47 -24 92 -28 101 -5 10 -21 44 -37 77 -119 244 -391 407 -675 404 -59 -1 -119 -8 -159 -20z" />{" "}
      </g>{" "}
    </svg>
  );
};

export default ShopcartIcon;

import React from "react";
import { DOMAIN } from "../../../../config/env";
import { RESSOURCE } from "../../../../config/constants";
import "./style.scss";

const GuideTaille = () => {
  const guides = [
    {
      id: 1,
      title: "Gilet multipoches",
      imagePath: "2022_2018_Boutique_en_ligne_VISUELS_S11_03_02_1128_77.png",
      pdfLink: "mesure_gilet_multipoches.pdf",
    },
    {
      id: 2,
      title: "Polo mixte MC",
      imagePath: "2022_2018_Boutique_en_ligne_VISUELS_S_05_01_1454_29.png",
      pdfLink: "mesure_polo_mixte_mc.pdf",
    },
    {
      id: 3,
      title: "Veste Polaire",
      imagePath: "2022_2018_Boutique_en_ligne_VISUELS_S7_05_01_1348_12.png",
      pdfLink: "mesure_veste_polaire.pdf",
    },
    {
      id: 4,
      title: "Veste polaire SM",
      imagePath: "2022_2018_Boutique_en_ligne_VISUELS_S5_05_01_1229_49.png",
      pdfLink: "mesure_veste_polaire_sm.pdf",
    },
  ];
  return (
    <div>
      <h2 className="guide-titre">GUIDE DES TAILLES</h2>
      <div className="guide-container">
        {guides.map((guide) => (
          <a
            href={
              DOMAIN.URL + "/" + RESSOURCE.BOUTIQUE_AUTRE + "/" + guide.pdfLink
            }
            key={guide.id}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="guide-card">
              <img
                src={
                  DOMAIN.URL + "/" + RESSOURCE.BOUTIQUE + "/" + guide.imagePath
                }
                alt={guide.title}
              />
              <h3>{guide.title}</h3>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default GuideTaille;

import React from "react";
import "./style.scss";
import { DOMAIN } from "../../../../config/env";

const BDCExcelCommandButton = ({ fournisseur }) => {
  return (
    <>
      <a
        className="excel-command-btn"
        href={`${DOMAIN.URL_WEB}command-excel/${fournisseur.bi_code}`}
        rel="noreferrer"
      >
        articles&nbsp;excel
      </a>
    </>
  );
};

export default BDCExcelCommandButton;

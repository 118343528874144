// src/App.js
import React from "react";
import OperationProvider from "./operationContext";
import Operation from "./components/operation";
import "./style.scss";

const App = () => {
  return (
    <OperationProvider>
      <Operation />
    </OperationProvider>
  );
};

export default App;

import { DOMAIN } from '../config/env';
import api from './apiClient';

/**
 * Renvoie  la liste des sections avec les prestataires associés
 * filtrés par le magasin spécifié.
 */
export async function getSectionsWithPrestataires(mag_code) {
  let sections = {};

  await api
    .get(DOMAIN.URL_API + 'prestations/prestataires/' + mag_code)
    .then((res) => {
      sections = res.data;
    });
  return sections;
}
/**
 * Renvoie  la liste des formations pour un magasin donné
 *
 */
export async function getFormations(mag_code) {
  let formations = {};

  await api
    .get(DOMAIN.URL_API + 'prestations/formations/' + mag_code)
    .then((res) => {
      formations = res.data;
    });
  return formations;
}

export async function getPlanosByMetier(mag_code, met_code) {
  let planos = {};

  await api
    .get(
      DOMAIN.URL_API + 'planos/' + mag_code + '/metier/' + met_code
    )
    .then((res) => {
      planos = res.data;
    });
  return planos;
}

export async function getPlanoDetail(mag_code, pga_code) {
  let planos = {};

  await api
    .get(
      DOMAIN.URL_API + 'planosDetail/' + mag_code + '/pga/' + pga_code
    )
    .then((res) => {
      planos = res.data;
    });
  return planos;
}

/**
 * Effectue une requête asynchrone pour obtenir la classification.
 * @param {string} mag_code - Le code du magasin.
 * @returns {Promise<any>} Une promesse résolue avec les données de réponse.
 */
export async function getClassification(mag_code) {
  const res = await api.get(
    DOMAIN.URL_API + 'classification/' + mag_code
  );
  return res.data;
}

/*
récupére une liste de contrats en fonction du metier passé en paramètre
si on passe metier =0, on récupère l'intégralité des contrats

 */
export async function getContratsByMetier(mag_code, met_code) {
  let contrats = {};

  await api
    .get(
      DOMAIN.URL_API + 'contrats/' + mag_code + '/metier/' + met_code
    )
    .then((res) => {
      contrats = res.data;
    });
  return contrats;
}

/*
Récupère la liste des articles sous forme paginés
en fonction de magasin
en focntion des filtres passés en paramètres

 */
export async function getArticles(
  mag_code,
  data = null,
  pageIndex = 1
) {
  const res = await api.post(
    DOMAIN.URL_API + 'articles/' + mag_code + '?page=' + pageIndex,
    {
      ...data,
      // Ajouter le paramètre "page" avec la valeur de "pageIndex"
    }
  );

  return res.data;
}

/**
 * Télécharge un fichier excel en fonction des filtres passé en argument
 * @param {*} mag_code 
 * @param {*} con_code 
 * @returns 
 */
export async function exportArticlesToExcelFile(mag_code, data) {
  try {
    const res = await api.post(`${DOMAIN.URL_WEB}export-articles/${mag_code}/filtres`, {
      ...data
    }, {responseType: 'blob'})
    return res
  }catch(error) {
    throw error;
  }
}

// récupére la liste des articles supprimés
export async function getArticlesSupprimes(mag_code, con_code) {
  const res = await api.get(
    DOMAIN.URL_API +
      'articlesSup/' +
      mag_code +
      '/contrat/' +
      con_code
  );
  return res.data;
}

export async function getBooks(mag_code) {
  const res = await api.get(DOMAIN.URL_API + 'books/' + mag_code);
  return res.data;
}

export async function getContratDetail(mag_code, con_code) {
  const res = await api.get(
    DOMAIN.URL_API +
      'contratsDetails/' +
      mag_code +
      '/contrat/' +
      con_code
  );
  return res.data;
}

export async function getMisesAjour(mag_code) {
  let maj = {};

  await api
    .get(DOMAIN.URL_API + 'contratsMAJ/' + mag_code)
    .then((res) => {
      maj = res.data;
    });
  return maj;
}

export async function favoris(mag_code, con_code, mode) {
  const functionName = mode === 1 ? 'addFavoris' : 'removeFavoris';

  const res = await api.get(
    `${DOMAIN.URL_API}${functionName}/${mag_code}/contrat/${con_code}`
  );
  return res.data;
}

import React from "react";
import { formatPrix } from "../../../../utils/dates";
import WarningIcon from "@mui/icons-material/Warning";
import { Link } from "react-router-dom"; // Import the Link component

const PendingOperations = ({
  data,
  handleSelectRow,
  selectedRows,
  handleDelete,
  handleConfirmSelected,
  errorMessage,
  total,
  handleUpdateOperation,
  idStatut,
}) => {
  return (
    <div className="mes-engagements-container">
      <h2 className="table-title">Plan de communication à valider</h2>
      <table className="engagements-table">
        <thead>
          <tr>
            <th></th>
            <th>MOIS</th>
            <th>OPÉRATION</th>
            <th>DATES D'ENGAGEMENT</th>
            <th>TOTAL</th>
            <th>ACTION</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.id}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedRows.includes(row.id) && !row.isExpired}
                  onChange={() => handleSelectRow(row.id)}
                  disabled={row.isExpired}
                />
              </td>
              <td>{row.mois}</td>
              <td>{row.operation}</td>
              <td>{row.datesEngagement}</td>
              <td className="total-column">{formatPrix(row.cout)}</td>
              <td className="action-column">
                <button
                  className="button-action updateButton"
                  onClick={() => handleUpdateOperation(row.id)}
                >
                  Modifier
                </button>
                <button
                  className="button-action deleteButton"
                  onClick={() => handleDelete(row.id)}
                >
                  Supprimer
                </button>
              </td>
            </tr>
          ))}
          <tr className="total-row">
            <td colSpan="4"></td>
            <td className="total-cell">{formatPrix(total)}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
      {errorMessage && <p className="error-message">{errorMessage}</p>}

      <div className="center-button">
        {idStatut === 1 || idStatut === 2 ? (
          <button onClick={handleConfirmSelected} className="engageButton">
            S'engager sur les communications sélectionnées
          </button>
        ) : (
          <div className="warning-message">
            <WarningIcon />
            Votre repiquage n'est pas encore validé. Nous vous remercions de
            nous le valider avant de pouvoir vous engager.
            <Link to="/cmNational/repiquage" className="warning-link">
              (Lien repiquage)
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default PendingOperations;

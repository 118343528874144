import React, { useState, useEffect } from "react";
import "./style.scss";

const EngagementModal = ({ isOpen, onClose, onConfirm, initialData }) => {
  const [formData, setFormData] = useState({
    cmz_libelle: "",
  });
  const [error, setError] = useState("");

  useEffect(() => {
    if (initialData) {
      setFormData({ cmz_libelle: initialData.cmz_libelle });
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const resetForm = () => {
    setFormData({ cmz_libelle: "" });
    setError("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.cmz_libelle) {
      setError("Le champ est obligatoire.");
      return;
    }
    setError("");
    onConfirm(formData);
    resetForm();
  };

  const handleClose = () => {
    onClose();
    resetForm();
  };

  if (!isOpen) return null;

  return (
    <div className="confirmation-modal-overlay">
      <div className="confirmation-modal-content">
        <div className="confirmation-modal-header">
          <div className="confirmation-modal-title">
            <h3>{initialData ? "Modifier la zone" : "Ajouter une zone"}</h3>
          </div>
          <button className="confirmation-close-button" onClick={handleClose}>
            ×
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="confirmation-modal-body">
            <div className="floating-label">
              <textarea
                name="cmz_libelle"
                value={formData.cmz_libelle}
                onChange={handleChange}
                placeholder=" "
              />
              <label>Saisir le Nom de la zone</label>
            </div>
            {error && <p className="error-message">{error}</p>}
          </div>
          <div className="confirmation-modal-footer">
            <button
              type="button"
              className="confirmation-cancel-button"
              onClick={handleClose}
            >
              Annuler
            </button>
            <button type="submit" className="confirmation-add-button">
              {initialData ? "Modifier" : "Ajouter"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EngagementModal;

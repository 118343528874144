// src/components/Bonial.js
import React, { useContext } from "react";
import { OperationContext } from "../operationContext";
import BonialSkeleton from "./skeleton/bonialSkeleton";

const Bonial = () => {
  const { formState, handleBonialChoixChange, handleInputChange, isLoading } =
    useContext(OperationContext);
  const preventNegativeInput = (e) => {
    if (e.key === "-" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };
  if (isLoading) {
    return <BonialSkeleton />;
  }
  const isDisabled = formState.id_mag_cmd > 0;
  return (
    <div className="block small bonial">
      <h3>Bonial</h3>
      <h5>(Diffusion catalogue Digital)</h5>
      <div className="selection-container">
        <div className="left-selection">
          <div className="form-group">
            <label htmlFor="bonial-choix">Mon choix :</label>
            {formState.mo_bonial === "1" && (
              <label htmlFor="Qte_bonial">Quantité :</label>
            )}
          </div>
        </div>
        <div className="right-selection">
          <div className="form-group">
            <select
              id="bonial-choix"
              className="styled-select"
              name="mo_bonial"
              value={formState.mo_bonial}
              onChange={(e) => handleBonialChoixChange(e.target.value)}
              disabled={isDisabled}
            >
              <option value="0">Non</option>
              <option value="1">Oui</option>
            </select>
            {formState.mo_bonial === "1" && (
              <input
                id="Qte_bonial"
                name="mo_bonial_qte"
                type="number"
                value={formState.mo_bonial_qte}
                onChange={handleInputChange}
                disabled={isDisabled}
                onKeyDown={preventNegativeInput}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bonial;

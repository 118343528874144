import React, { useState } from "react";
import SearchIcon from "../../../../components/ui/icons/SearchIcon";
import AddIcon from "@mui/icons-material/Add";

const ITEMS_PER_PAGE = 10;

const SearchByDepartment = ({ onAdd, onSearch, searchResults }) => {
  const [departments, setDepartments] = useState(["", "", ""]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const handleInputChange = (index, event) => {
    const newDepartments = [...departments];
    newDepartments[index] = event.target.value;
    setDepartments(newDepartments);
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSearch(departments);
    setCurrentPage(1);
  };

  const filteredResults = searchResults.filter(
    (result) =>
      (result.usl_libelle &&
        result.usl_libelle.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (result.usl_code &&
        String(result.usl_code)
          .toLowerCase()
          .includes(searchTerm.toLowerCase()))
  );

  const totalPages = Math.ceil(filteredResults.length / ITEMS_PER_PAGE);
  const paginatedResults = filteredResults.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handleAddClick = (result) => {
    onAdd(result);
  };

  return (
    <div className="zone-distribution-container">
      <h2 className="table-title">Recherchez par Département</h2>
      <form onSubmit={handleSubmit} className="search-form">
        {departments.map((dept, index) => (
          <div className="floating-label" key={index}>
            <input
              type="text"
              id={`departmentInput${index}`}
              placeholder=" "
              value={dept}
              onChange={(e) => handleInputChange(index, e)}
            />
            <label htmlFor={`departmentInput${index}`}>
              Entrez un Département {index + 1}
            </label>
          </div>
        ))}
        <button className="search-button" type="submit">
          <SearchIcon />
          Recherchez
        </button>
      </form>

      {searchResults.length > 0 ? (
        <div className="table-container-dist">
          <div className="floating-label">
            <input
              type="text"
              id="searchTermInput"
              placeholder=" "
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
            <label htmlFor="searchTermInput">
              <div className="label-content">
                <SearchIcon />
                Recherchez par code USL et Libelle ...
              </div>
            </label>
          </div>
          <table className="distribution-table">
            <thead>
              <tr>
                <th style={{ width: "10%" }}>CODE USL</th>
                <th>Libelle (communes)</th>
                <th>A</th>
                <th>B</th>
                <th>C</th>
                <th>D1</th>
                <th>D2</th>
                <th>Total</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {paginatedResults.length > 0 ? (
                paginatedResults.map((result) => (
                  <tr key={result.usl_code}>
                    <td>{result.usl_code}</td>
                    <td>{result.usl_libelle}</td>
                    <td>{result.usl_qte_A}</td>
                    <td>{result.usl_qte_B}</td>
                    <td>{result.usl_qte_C}</td>
                    <td>{result.usl_qte_D1}</td>
                    <td>{result.usl_qte_D2}</td>
                    <td>
                      {result.usl_qte_A +
                        result.usl_qte_B +
                        result.usl_qte_C +
                        result.usl_qte_D1 +
                        result.usl_qte_D2}
                    </td>
                    <td className="column-actions">
                      <button
                        className="btn-add"
                        onClick={() => handleAddClick(result)}
                      >
                        <AddIcon fontSize="small" />
                        Ajouter
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9" style={{ textAlign: "center" }}>
                    Pas de donnée trouvée
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {totalPages > 1 && (
            <div className="pagination-controls">
              <button
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
              >
                Précédent
              </button>
              <span>
                Page {currentPage} - {totalPages}
              </span>
              <button
                onClick={() =>
                  setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                }
                disabled={currentPage === totalPages}
              >
                Suivant
              </button>
            </div>
          )}
        </div>
      ) : (
        <div className="message">
          <p>Entrez un département pour afficher le tableau !</p>
        </div>
      )}
    </div>
  );
};

export default SearchByDepartment;

import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import { Link, useOutletContext, useParams } from 'react-router-dom';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import useAuth from '../../../../hooks/useAuth';
import {
  getCommandInfoProducts,
  getPaginations,
} from '../../../../services/communication_apis';
import ReturnBtn from '../../../../components/ui/button/ReturnBtn';
import BDCselectButton from './../../../../components/ui/input/BDCselectButton';
import BDCinputButton from './../../../../components/ui/input/BDCinputButton';
import LoaderComponent from '../../../../components/ui/loader/LoaderComponent';
import BDCproductCard from '../../../../components/ui/cards/communication/BDCproductCard';
import Select from '../../../../components/ui/input/Select';
import Input from '../../../../components/ui/input/Input';

const BDCselectionProduits = () => {
  const params = useParams();
  const [loading, setLoading] = useState(true); // Indicate the call has not finished. Default to true

  const prospectusId = params.prospectusId; // Identifiant du prospectus
  const { prospectusList } = useOutletContext(); // Complet list of prospectus
  const [prospectus, setProspectus] = useState({}); // Actual prospectus
  const { magasin } = useAuth(); // Magasin code

  const [products, setProducts] = useState({}); // Liste des produits à afficher
  const [filteredProducts, setFilteredProducts] = useState({}); // Liste des produits filtrés
  const { fournisseursList } = useOutletContext(); // Liste fournisseurs
  const { handleRefreshFournisseursCommand } = useOutletContext();
  const [fournisseursProspectus, setFournisseursProspectus] =
    useState({}); // Liste fournisseurs prospectus

  const [paginations, setPaginations] = useState({});

  console.log('Fournisseurs list :', fournisseursList)
  console.log('Fournisseurs Prospectus :', fournisseursProspectus)

  const [filter, setFilter] = useState({
    con_code: '', // Fournisseur
    pbd_produit: '', // designation
    pbd_reference: '', // reference
    pbd_gencod: '', // gencode
    psa_type: '', // pagination
  });

  /** HandleChange filter */
  const handleChangeFilter = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFilter({
      ...filter,
      [name]: value,
    });
  };

  /**
   *  Filter actualisation for product search
   */
  React.useEffect(() => {
    try {
      if (products.length) {
        // Filtre fournisseur
        let filterProductTemp = products.filter((product) => {
          return filter.con_code !== ''
            ? product.con_code === parseInt(filter.con_code)
            : product;
        });
        // Filtre designation
        filterProductTemp = filterProductTemp.filter((product) => {
          return filter.pbd_produit !== ''
            ? product.pbd_produit
                .toLowerCase()
                .includes(filter.pbd_produit.toLowerCase())
            : product;
        });
        // Filtre reference
        filterProductTemp = filterProductTemp.filter((product) => {
          return filter.pbd_reference !== ''
            ? product.pbd_reference
                .toLowerCase()
                .startsWith(filter.pbd_reference)
            : product;
        });
        // Filtre gencod
        filterProductTemp = filterProductTemp.filter((product) => {
          return filter.pbd_gencod !== ''
            ? product.pbd_gencod
                .toLowerCase()
                .startsWith(filter.pbd_gencod)
            : product;
        });
        // Filtre pagination
        filterProductTemp = filterProductTemp.filter((product) => {
          if (filter.psa_type !== '') {
            if (product.pagination) {
              return product.pagination.find(
                (pagination) =>
                  pagination.ps_p_code === parseInt(filter.psa_type)
              );
            }
            // Return product hors depliant (=-1)
            return product.pbd_flag_hd === parseInt(filter.psa_type);
          } else {
            return product;
          }
        });
        setFilteredProducts(filterProductTemp);
      }
    } catch (err) {
      console.log(err);
    } finally {
      // setLoading(false);
    }
  }, [filter, products]);

  /**
   *  Component set up + data actualisation
   */
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        // Get fournisseurs for this prospectus
        if (fournisseursList.length > 0) {
          const fournisseursFiltered = fournisseursList.filter(
            (fournisseur) => {
              return (
                fournisseur.psp_cprospectus === parseInt(prospectusId)
              );
            }
          );
          setFournisseursProspectus(fournisseursFiltered);
        }

        if (prospectusList.length > 0) {
          // Get the actual prospectus
          const prospectusFind = prospectusList.find(
            (p) => p.psp_cprospectus === parseInt(prospectusId)
          );
          setProspectus(prospectusFind);

          // Get products with command info belong to this prospectus
          await getCommandInfoProducts(
            prospectusId,
            magasin.mag_code
          ).then((res) => {
            setProducts(res.data);
            setFilteredProducts(res.data);
          });

          // Get paginiations of this prospectus
          await getPaginations(prospectusId).then((res) => {
            setPaginations(res.data.list);
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [magasin.mag_code, fournisseursList, prospectusId, prospectusList]);

    // FixedButtonDisplayer

    const tutoIsVisible = useRef(null)
    const [buttonIsFixed, setButtonIsFixed] = useState(false);
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if(!entry.isIntersecting) {
            setButtonIsFixed(true)
          } else {
            setButtonIsFixed(false)
          }
        }, {
          root: null,
          threshold: 1.0
        }
      );

      if(tutoIsVisible.current) {
        observer.observe(tutoIsVisible.current);
      }

      return () => {
        if(tutoIsVisible.current) {
          observer.unobserve(tutoIsVisible.current)
        }
      }
    }, [])

  return (
    <div className="selection-produits">
      <ReturnBtn />
      <header className="selection-produits__head">
        <h3> {prospectus.psp_libelle} </h3>
        <div className="bdc-tuto"
          ref={tutoIsVisible}
        >
          {/* <WarningAmberIcon /> */}
          <span> Étapes pour la validation d'une commande </span>
          <ul>
            <li>
              - Choississez vos produits en saisissant la quantité et
              le prix associé au conditionnement.
            </li>
            <li>
              - Accédez à votre liste de commandes en cliquant sur le
              bouton ci dessous.
              <div>
                <Link
                  to={`/bdc/commanderecap/${prospectusId}`}
                  className={`btn-see-cart  ${buttonIsFixed && 'fixed-button'}`}
                  onClick={handleRefreshFournisseursCommand}
                >
                  <ShoppingBagOutlinedIcon className="btn-icon" />
                  Voir les commandes
                </Link>
              </div>
            </li>
            <li>
              - Récupérez votre bon de commande
              <span> au format PDF </span>.
            </li>
            <li>
              - Enregistrez <span> et </span> envoyez le{' '}
              <span>bon de commande PDF </span> au fournisseur.
            </li>
          </ul>
        </div>
        <div className="products-actions">
          <div className="products-filter">
            <Select
              name="con_code"
              label="Fournisseur"
              value={filter.con_code}
              onChange={(event) => handleChangeFilter(event)}
            >
              <option value={''}>Tous</option>
              {fournisseursProspectus.length >= 1 &&
                fournisseursProspectus.map((fournisseur) => {
                  return (
                    <option
                      key={fournisseur.con_code}
                      value={fournisseur.con_code}
                    >
                      {fournisseur.con_nom}
                    </option>
                  );
                })}
            </Select>
            <Input
              label="Désignation"
              name="pbd_produit"
              placeholder=" "
              value={filter.pbd_produit}
              onChange={(event) => handleChangeFilter(event)}
            />
            <Input
              label="Référence"
              name="pbd_reference"
              placeholder=" "
              value={filter.pbd_reference}
              onChange={(event) => handleChangeFilter(event)}
            />
            <Input
              label="Gencod"
              name="pbd_gencod"
              placeholder=" "
              value={filter.pbd_gencod}
              onChange={(event) => handleChangeFilter(event)}
            />
            <Select
              name="psa_type"
              label="Pagination"
              value={filter.psa_type}
              onChange={(event) => handleChangeFilter(event)}
            >
              <option value={''}>Toutes</option>
              {paginations.length >= 1 &&
                paginations.map((pagination, i) => {
                  return (
                    <option
                      key={pagination.psa_type}
                      value={pagination.psa_type}
                    >
                      {pagination.ps_p_libelle} - {pagination.psa_libelle}
                    </option>
                  );
                })}
              <option value={-1}>Hors dépliant</option>
            </Select>
          </div>
        </div>
      </header>

      <section className="products-list">
        {loading ? (
          <div className="products-list__loader">
            <LoaderComponent />
          </div>
        ) : (
          <>
            {filteredProducts.length ? (
              filteredProducts.map((product) => {
                return (
                  <React.StrictMode
                    key={'product_' + product.pbd_ligne}
                  >
                    <BDCproductCard
                      product={product}
                      // onQuantityUpdate={handleQuantityChange}
                    />
                  </React.StrictMode>
                );
              })
            ) : (
              <>Aucuns produits trouvés</>
            )}
          </>
        )}
      </section>
    </div>
  );
};

export default BDCselectionProduits;

// src/components/skeleton/BudgetSkeleton.js
import React from "react";
import "./skeleton.scss";

const BudgetSkeleton = () => {
  return (
    <div className="block large title-operation">
      <h3 className="skeleton skeleton-title" aria-hidden="true"></h3>
      <div className="selection-container">
        <div className="space-small"></div>

        <div className="skeleton skeleton-table"></div>
      </div>
    </div>
  );
};

export default BudgetSkeleton;

import { useOutletContext, useParams } from "react-router-dom";
import ReturnBtn from "../../../../components/ui/button/ReturnBtn";
import React from "react";
import TitleRowTable from "../../../../components/ui/tables/TitleRowTable";
import { DOMAIN } from "../../../../config/env";
import { RESSOURCE } from "../../../../config/constants";
import PDFIcon from "../../../../components/ui/icons/PDFIcon";
import "./style.scss";

const MDGTelechargements = () => {
    const { dsr_code } = useParams();
    const { mdgList } = useOutletContext();

    const mdg = React.useMemo(() => {

        return mdgList?.find((dsr) => {
            return dsr.dsr_code === parseInt(dsr_code);
        });
    }, [mdgList, dsr_code]);

    const titleRow = { accessor: 'dc_libelle' };
    const dataRow = { accessor: 'downloads' };
    const columns = React.useMemo(
        () => [
            {
                accessor: 'dl_libelle',
            },
            {
                Cell: (row) => (
                    <a
                        href={`${DOMAIN.URL}/${RESSOURCE.DOWNLOAD}/${row.dl_fichier}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <PDFIcon width={20} height={20} />
                    </a>
                ),
            },
        ],
        []
    );

    return (

        <div className="mdg-telechargements-page">
            <ReturnBtn />
            <h3 className="mdg-telechargements-page-title">{mdg.mdg_nom}</h3>
            <section className="mdg-telechargements-table">
                <TitleRowTable
                    columns={columns}
                    data={mdg.mdg_rubriques}
                    titleRow={titleRow}
                    dataRow={dataRow}
                    header={false}
                />
            </section>

        </div>
    )
}
export default MDGTelechargements;
import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonLoader = () => {
  return (
    <div>
      <div style={{ marginBottom: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <Skeleton height={40} width={500} />
        </div>
        {/* Skeleton pour l'entête du tableau */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <Skeleton height={45} width={100} />
          <Skeleton height={45} width={200} />
          <Skeleton height={45} width={500} />
          <Skeleton height={45} width={300} />
          <Skeleton height={45} width={100} />
          <Skeleton height={45} width={400} />
        </div>
        {/* Skeleton pour les lignes du tableau */}
        {[1, 2].map((i) => (
          <div
            key={i}
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
              marginLeft: "20px",
              marginRight: "20px",
            }}
          >
            <Skeleton height={30} width={100} />
            <Skeleton height={30} width={200} />
            <Skeleton height={30} width={500} />
            <Skeleton height={30} width={300} />
            <Skeleton height={30} width={100} />
            <Skeleton height={30} width={400} />
          </div>
        ))}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "30px",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <Skeleton height={45} width={100} />
          <Skeleton height={45} width={200} />
          <Skeleton height={45} width={500} />
          <Skeleton height={45} width={300} />
          <Skeleton height={45} width={100} />
          <Skeleton height={45} width={400} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "50px",
          }}
        >
          <Skeleton height={40} width={300} />
        </div>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          <Skeleton height={40} width={500} />
        </div>
        {/* Skeleton pour l'entête du tableau */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <Skeleton height={45} width={325} />
          <Skeleton height={45} width={625} />
          <Skeleton height={45} width={425} />
          <Skeleton height={45} width={225} />
        </div>
        {/* Skeleton pour les lignes du tableau */}
        {[1, 2].map((i) => (
          <div
            key={i}
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
              marginLeft: "20px",
              marginRight: "20px",
            }}
          >
            <Skeleton height={30} width={325} />
            <Skeleton height={30} width={625} />
            <Skeleton height={30} width={425} />
            <Skeleton height={30} width={225} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SkeletonLoader;

import "./style.scss";

const ExternalLink = ({
  libelle,
  sublibelle,
  className,
  href,
  icon,
  styleType = "filled", // Par défaut, le style est 'filled'
  ...rest
}) => {
  if (!libelle) return null;

  const combinedClassName = `${className} ${styleType}-link`;

  return (
    <a
      className={combinedClassName}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      {...rest}
    >
      {icon}
      <span className="libelle">
        {libelle}
        <span className="sublibelle">{sublibelle}</span>
      </span>
    </a>
  );
};

export default ExternalLink;

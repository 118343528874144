import React from "react";
import Drawer from "../../ui/drawers/Drawer";
import "./style.scss";
import { useColoredLinks } from "./links";
import AcceuilBannerCarousel from "../../ui/carousels/AcceuilBannerCarousel";

const CmNationaleLayout = ({ children }) => {
  return (
    <div className="page-banner-layout">
      <header className="page-banner-layout__banner">
        <AcceuilBannerCarousel />
      </header>
      <div className="page-banner-layout__bg"></div>
      <div className="page-banner-layout__outlet">
        <div className="home-layout">
          <div className="home-layout__content">{children}</div>
          <Drawer links={useColoredLinks()} />
        </div>
      </div>
    </div>
  );
};

export default CmNationaleLayout;

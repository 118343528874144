import { DOMAIN } from "../config/env";
import api from "./apiClient";

/** Récupère toute les opération celon un magasin */
export async function getOperationsByMagasin(mag_code) {
  try {
    const response = await api.get(
      DOMAIN.URL_API + "cx/operations/magasin/" + mag_code
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch operations:", error);
    throw error.response ? error.response.data : new Error("An error occurred");
  }
}
/** Récupère toute les magOpe celon un magasin */
export async function getMagOpeByMagOperation(mag_code, annee) {
  try {
    const response = await api.get(
      DOMAIN.URL_API +
        "cx/getMagOpeByMagOperation/magasin/" +
        mag_code +
        "/" +
        annee
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch operations:", error);
    throw error.response ? error.response.data : new Error("An error occurred");
  }
}
/** Crée une commande d'opération */
export async function createCommande(data) {
  try {
    const response = await api.post(DOMAIN.URL_API + "cx/createCommande", data);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch operations:", error);
    throw error.response ? error.response.data : new Error("An error occurred");
  }
}
/** Supprime une MagOpe */
export async function deleteMagOpe(data) {
  try {
    const response = await api.post(DOMAIN.URL_API + "cx/deleteMagOpe", data);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch operations:", error);
    throw error.response ? error.response.data : new Error("An error occurred");
  }
}

/** Récupère toute les information de l'opération et celle du magasin s'il il est déja engager */
export async function getOperationByMagasin(id_operation, mag_code) {
  try {
    const response = await api.get(
      DOMAIN.URL_API + "cx/operation/" + id_operation + "/magasin/" + mag_code
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch operations:", error);
    throw error.response ? error.response.data : new Error("An error occurred");
  }
}

/** Sauvegarde CxMagOpe */
export async function saveMagOpe(data) {
  try {
    const response = await api.post(DOMAIN.URL_API + "cx/saveMagOpe", data);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch operations:", error);
    throw error.response ? error.response.data : new Error("An error occurred");
  }
}
/** Sauvegarde les option PLV */
export async function saveMapOption(data) {
  try {
    const response = await api.post(
      DOMAIN.URL_API + "cx/saveMagOpeOption",
      data
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch operations:", error);
    throw error.response ? error.response.data : new Error("An error occurred");
  }
}
/** Budgetise l'operation selon les tableau CxOperation et CxMagOpe */
export async function budgetMagOpe(data) {
  try {
    const response = await api.post(DOMAIN.URL_API + "cx/budgetMagOpe", data);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch operations:", error);
    throw error.response ? error.response.data : new Error("An error occurred");
  }
}
// ===== ZONE DISTRIBUTION =====//

export async function getMagZoneByMag(mag_code) {
  try {
    const response = await api.get(DOMAIN.URL_API + "magZoneByMag/" + mag_code);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch operations:", error);
    throw error.response ? error.response.data : new Error("An error occurred");
  }
}
export async function getMagZoneUsl(id_mag_zone) {
  try {
    const response = await api.get(
      DOMAIN.URL_API + "magZoneUsl/" + id_mag_zone
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch operations:", error);
    throw error.response ? error.response.data : new Error("An error occurred");
  }
}

export async function getUslByDepts(data) {
  try {
    const response = await api.post(DOMAIN.URL_API + "uslDepts", data);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch operations:", error);
    throw error.response ? error.response.data : new Error("An error occurred");
  }
}
export async function addMagZone(data) {
  try {
    const response = await api.post(DOMAIN.URL_API + "addMagZone", data);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch operations:", error);
    throw error.response ? error.response.data : new Error("An error occurred");
  }
}
export async function addMagZoneUsl(data) {
  try {
    const response = await api.post(DOMAIN.URL_API + "addMagZoneUsl", data);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch operations:", error);
    throw error.response ? error.response.data : new Error("An error occurred");
  }
}

export async function deleteMagZone(data) {
  try {
    const response = await api.post(DOMAIN.URL_API + "deleteMagZone", data);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch operations:", error);
    throw error.response ? error.response.data : new Error("An error occurred");
  }
}
export async function deleteMagZoneUSl(data) {
  try {
    const response = await api.post(DOMAIN.URL_API + "deleteMagZoneUsl", data);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch operations:", error);
    throw error.response ? error.response.data : new Error("An error occurred");
  }
}
export async function updateMagZone(data) {
  try {
    const response = await api.post(DOMAIN.URL_API + "updateMagZone", data);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch operations:", error);
    throw error.response ? error.response.data : new Error("An error occurred");
  }
}

export async function deleteAllMagZoneUsl(data) {
  try {
    const response = await api.post(
      DOMAIN.URL_API + "deleteAllMagZoneUsl",
      data
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch operations:", error);
    throw error.response ? error.response.data : new Error("An error occurred");
  }
}

export async function duplicateMagZone(id_mag_zone) {
  try {
    const response = await api.get(
      DOMAIN.URL_API + "duplicateMagZone/" + id_mag_zone
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch operations:", error);
    throw error.response ? error.response.data : new Error("An error occurred");
  }
}

/** Import Excel File */
export async function importExcelFile(file, id_mag_zone) {
  const formData = new FormData();
  formData.append("importfile", file); // Utilisez 'importfile' comme clé
  formData.append("id_mag_zone", id_mag_zone);

  try {
    const response = await api.post(
      DOMAIN.URL_API + "importFichierUsl",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to import Excel file:", error);
    throw error.response ? error.response.data : new Error("An error occurred");
  }
}

// ===== REPIQUAGE =====//

/** Récupère les repiquages du magasin  */
export async function getRepiquageByMag(mag_code) {
  try {
    const response = await api.get(
      DOMAIN.URL_API + "cx/magRepiquage/magcode/" + mag_code
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch operations:", error);
    throw error.response ? error.response.data : new Error("An error occurred");
  }
}
/** Sauvegarde un repiquage  */
export async function saveRepiquage(data) {
  try {
    const response = await api.post(DOMAIN.URL_API + "cx/saveRepiquage", data);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch operations:", error);
    throw error.response ? error.response.data : new Error("An error occurred");
  }
}
/** Envoie un email quand une action est faite sur un repiquage  */
export async function emailRepiquage(data) {
  try {
    const response = await api.post(DOMAIN.URL_API + "cx/emailRepiquage", data);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch operations:", error);
    throw error.response ? error.response.data : new Error("An error occurred");
  }
}

// src/components/GeneralSkeleton.js
import React from "react";
import "./skeleton.scss";

const GeneralSkeleton = () => {
  return (
    <div className="block medium title-operation">
      <h3 className="skeleton skeleton-text" aria-hidden="true"></h3>
      <div className="selection-container">
        <div className="left-selection">
          <div className="form-group">
            <div className="skeleton skeleton-text"></div>
            <div className="skeleton skeleton-select"></div>
            <div className="skeleton skeleton-text"></div>
            <div className="skeleton skeleton-select"></div>
            <div className="skeleton skeleton-text"></div>
            <div className="skeleton skeleton-select"></div>
          </div>
        </div>
        <div className="right-selection">
          <div className="form-group">
            <div className="skeleton skeleton-text"></div>
            <div className="skeleton skeleton-radio"></div>
            <div className="skeleton skeleton-radio"></div>
            <div className="skeleton skeleton-radio"></div>
            <div className="skeleton skeleton-text"></div>
            <div className="skeleton skeleton-text"></div>
          </div>
        </div>
      </div>
      <div className="footer-section">
        <div className="skeleton skeleton-text"></div>
        <div className="skeleton skeleton-textarea"></div>
      </div>
    </div>
  );
};

export default GeneralSkeleton;

import React, { useState, useEffect } from "react";
import { getCommandeByMagasin } from "../../../../services/boutique_api";
import useAuth from "../../../../hooks/useAuth";
import { formatDate } from '../../../../utils/dates';
import "./style.scss";
import {
  FaCalendarAlt,
  FaStore,
  FaUser,
  FaEnvelope,
  FaPhone,
  FaMapMarkerAlt,
  FaCommentDots,
  FaInfoCircle,
  FaEye,
} from "react-icons/fa";

function ModalCommande ({ show, onClose, commande }) {
  if (!show || !commande) return null;

  return (
    <div className="modal-commande">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <h2>Ma commande</h2>
        <hr />

        <div className="order-info-grid">
          {commande.Date && (
            <div className="order-info-item">
              <FaCalendarAlt className="order-info-icon" />
              <span className="order-info-label">Date :</span>
              <span className="order-info-value">
                {formatDate(commande.Date)}
              </span>
            </div>
          )}
          {commande.Magasin && (
            <div className="order-info-item">
              <FaStore className="order-info-icon" />
              <span className="order-info-label">Magasin :</span>
              <span className="order-info-value">{commande.Magasin}</span>
            </div>
          )}
          {commande.Contact && (
            <div className="order-info-item">
              <FaUser className="order-info-icon" />
              <span className="order-info-label">Contact :</span>
              <span className="order-info-value">{commande.Contact}</span>
            </div>
          )}
          {commande.Mail && (
            <div className="order-info-item">
              <FaEnvelope className="order-info-icon" />
              <span className="order-info-label">Mail :</span>
              <span className="order-info-value">{commande.Mail}</span>
            </div>
          )}
          {commande.Telephone && (
            <div className="order-info-item">
              <FaPhone className="order-info-icon" />
              <span className="order-info-label">Téléphone :</span>
              <span className="order-info-value">{commande.Telephone}</span>
            </div>
          )}
          {commande.AdresseDeLivraison && (
            <div className="order-info-item">
              <FaMapMarkerAlt className="order-info-icon" />
              <span className="order-info-label">Adresse de livraison :</span>
              <span className="order-info-value">
                {commande.AdresseDeLivraison}
              </span>
            </div>
          )}
          {commande.Commentaire && (
            <div className="order-info-item">
              <FaCommentDots className="order-info-icon" />
              <span className="order-info-label">Commentaire :</span>
              <span className="order-info-value">{commande.Commentaire}</span>
            </div>
          )}
          {commande.Etat && (
            <div className="order-info-item">
              <FaInfoCircle className="order-info-icon" />
              <span className="order-info-label">État :</span>
              <span className="order-info-value">{commande.Etat}</span>
            </div>
          )}
        </div>

        <table className="commande-table">
          <thead>
            <tr>
              <th>Référence</th>
              <th>Désignation</th>
              <th>Conditionnement</th>
              <th>Prix Vente HT</th>
              <th>Quantité</th>
              <th>Taille</th>
              <th>Couleur</th>
              <th>Logo</th>
              <th>Montant HT</th>
            </tr>
          </thead>
          <tbody>
            {/* Vous devrez ajuster la map en fonction de la structure de vos données */}
            {commande.DetailDeLaCommande.map((detail, index) => (
              <tr key={index}>
                <td>{detail.reference}</td>
                <td>{detail.designation}</td>
                <td>{detail.conditionnement}</td>
                <td>{detail.prixVenteHT} €</td>
                <td>{detail.quantite}</td>
                <td>{detail.taille}</td>
                <td>{detail.couleur}</td>
                <td>{detail.logo}</td>
                <td>{detail.montantHT} €</td>
              </tr>
            ))}
            <tr className="total-row">
              <td colSpan="8" style={{ textAlign: "center" }}>
                Montant commande HT hors frais d'expédition :
              </td>
              <td style={{ textAlign: "center" }}>
                {commande.MontantCommandeHT} €
              </td>
            </tr>
          </tbody>
        </table>
        <hr />
        <div className="form-actions">
          <button className="close-modal-btn" onClick={onClose}>
            Fermer
          </button>
        </div>
      </div>
    </div>
  );
}

const Commande = () => {
  const [commande, setCommande] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedCommande, setSelectedCommande] = useState(null);
  const { magasin } = useAuth();

  useEffect(() => {
    async function fetchCommandes () {
      try {
        const data = await getCommandeByMagasin(magasin.mag_code);
        setCommande(data);
      } catch (error) {
        console.error("Erreur lors de la récupération des commandes :", error);
      }
    }

    if (magasin.mag_code) {
      fetchCommandes();
    }
  }, [magasin.mag_code]);


  const handleOpenModal = (commandeItem) => {
    setSelectedCommande(commandeItem);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedCommande(null);
    setShowModal(false);
  };

  return (
    <div >
      <div className="titre-panier">Mes commandes</div>
      <div className="table-mes-commandes">
        <table className="styled-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Magasin</th>
              <th>Contact</th>
              <th>Mail</th>
              <th>Montant</th>
              <th>État</th>
              <th>Détail</th>
            </tr>
          </thead>
          <tbody>
            {commande.map((item, index) => (
              <tr key={index}>
                <td>{formatDate(item.Date)}</td>
                <td>{item.Magasin}</td>
                <td>{item.Contact}</td>
                <td>{item.Mail}</td>
                <td>{item.MontantCommandeHT} €</td>
                <td>{item.Etat}</td>
                <td>
                  <button
                    className="detailButton"
                    onClick={() => handleOpenModal(item)}
                  >
                    <FaEye />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ModalCommande
        show={showModal}
        onClose={handleCloseModal}
        commande={selectedCommande}
      />
    </div>
  );
};
export default Commande;
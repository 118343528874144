import { saveCxStat } from "../services/stat_api";

class Stat {
    /* 
    * Permet d'ajouter le suivi des cliques.
    * Champs a fournir (mag_code , libelle, prs_cprospectus(facultatif), type (stat_type))
    **/
    static async addStat(data) {
        try {
            const response = await saveCxStat(data)
            return response
        } catch (error) {
            console.error(error);
        }
    }
}

export default Stat;
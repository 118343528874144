import React from 'react';
import AcceuilBannerCarousel from '../../../ui/carousels/AcceuilBannerCarousel';

const FournisseursProduitsLayout = ({ children }) => {
  return (
    <div className="page-banner-layout">
      <header className="page-banner-layout__banner">
        <AcceuilBannerCarousel />
      </header>
      <div className="page-banner-layout__bg"></div>
      <div className="page-banner-layout__outlet">
        <div className="home-layout">
          <div className="home-layout__content">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default FournisseursProduitsLayout;

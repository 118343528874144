import React from "react";
import { Outlet } from "react-router-dom";
import CmNationaleLayout from "../../../components/layout/CmNationaleLayout";
import { YearProvider } from "../../../contexts/YearContext"; // Import the provider

import "./style.scss";

const CmNationale = () => {
  return (
    <YearProvider>
      <CmNationaleLayout>
        <Outlet />
      </CmNationaleLayout>
    </YearProvider>
  );
};

export default CmNationale;

import React, { useState } from "react";
import { DOMAIN } from "../../../../../config/env";
import { RESSOURCE } from "../../../../../config/constants";

const Attente = ({ data, onSubmit }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [comment, setComment] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Ajout de l'état de chargement
  const [zoomImage, setZoomImage] = useState(false);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    setErrorMessage("");
  };
  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmit = async () => {
    if (!selectedOption) {
      setErrorMessage("Veuillez sélectionner une option.");
      return;
    }
    if (selectedOption === "corrige" && comment.trim() === "") {
      setErrorMessage(
        "Le commentaire est obligatoire lorsque vous corrigez le repiquage."
      );
      return;
    }

    const updatedData = {
      ...data,
      id_statut: selectedOption === "valide" ? 1 : 2,
      mr_commentaire: comment,
    };

    setIsLoading(true); // Début du chargement
    try {
      await onSubmit(updatedData);
    } catch (error) {
      console.error("Erreur lors de l'envoi des données", error);
    } finally {
      setIsLoading(false); // Fin du chargement
    }
  };

  return (
    <div className="attente-container">
      <img
        src={`${DOMAIN.URL_WEB}${RESSOURCE.PHOTO_REPIQUAGE}${data.mr_fichier}`}
        alt=""
        className={`${zoomImage ? 'repiquage-image-zoom' : 'repiquage-image'}`}
        onClick={(e) => setZoomImage(!zoomImage)}
      />

      <div className="options">
        <label>
          <input
            type="radio"
            value="valide"
            checked={selectedOption === "valide"}
            onChange={handleOptionChange}
          />
          Je valide mon repiquage
        </label>
        <label>
          <input
            type="radio"
            value="corrige"
            checked={selectedOption === "corrige"}
            onChange={handleOptionChange}
          />
          Je corrige mon repiquage
        </label>
      </div>
      {selectedOption === "corrige" && (
        <div className="comment-section">
          <textarea
            placeholder="Laissez un commentaire..."
            value={comment}
            onChange={handleCommentChange}
            required
          />
        </div>
      )}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <button
        onClick={handleSubmit}
        className="submit-button"
        disabled={isLoading}
      >
        {isLoading && <div className="spinner"></div>}{" "}
        {isLoading ? "" : "Envoyer"}
      </button>
    </div>
  );
};

export default Attente;

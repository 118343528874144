import React from "react";

const OrderIcon = ({ height = 20, width = 20 }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox="0 0 300.000000 300.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <metadata>
        Created by potrace 1.10, written by Peter Selinger 2001-2011
      </metadata>
      <g
        transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M460 2408 c-46 -13 -87 -44 -105 -78 -19 -36 -21 -210 -3 -228 9 -9
119 -12 432 -12 400 0 423 -1 453 -20 48 -29 66 -71 61 -146 -4 -65 -23 -99
-73 -127 -14 -8 -151 -14 -450 -17 l-430 -5 -3 -45 c-5 -71 -9 -70 320 -70
313 0 324 -2 367 -56 29 -37 30 -145 1 -192 -36 -58 -58 -62 -370 -62 -175 0
-289 -4 -301 -10 -18 -10 -19 -23 -19 -209 0 -189 1 -200 23 -232 32 -48 79
-69 158 -69 l65 0 12 61 c30 164 151 279 311 295 177 19 350 -123 368 -302 l6
-54 347 0 346 0 12 61 c23 124 89 212 198 264 49 24 72 29 139 29 66 1 90 -4
137 -26 115 -54 193 -157 205 -272 l6 -56 106 0 c125 0 163 15 198 76 21 37
23 51 23 234 0 290 -19 344 -129 375 -94 26 -140 46 -172 72 -19 16 -58 82
-104 173 -85 170 -109 201 -179 237 -44 23 -68 27 -176 32 l-125 6 -5 147 c-6
164 -14 186 -81 220 -32 17 -89 18 -784 17 -464 0 -763 -5 -785 -11z m1871
-501 c71 -29 108 -74 178 -216 36 -75 68 -139 69 -143 2 -5 -101 -7 -230 -6
l-233 3 -3 184 c-2 141 1 187 10 193 19 13 167 3 209 -15z"
        />
        <path
          d="M196 2005 c-21 -22 -29 -52 -22 -91 13 -66 -2 -64 533 -64 470 0 484
1 503 20 14 14 20 33 20 68 0 87 32 82 -528 82 -424 0 -493 -2 -506 -15z"
        />
        <path
          d="M20 1570 c-31 -31 -28 -102 6 -129 26 -21 37 -21 469 -21 499 0 481
-3 492 75 4 31 0 46 -17 68 l-21 27 -455 0 c-441 0 -455 -1 -474 -20z"
        />
        <path
          d="M888 1099 c-78 -12 -144 -63 -187 -144 -30 -58 -29 -165 2 -224 35
-64 83 -108 143 -131 92 -34 170 -22 249 38 218 167 67 504 -207 461z m101
-153 c39 -20 61 -58 61 -103 0 -121 -164 -155 -216 -45 -21 44 -12 91 24 124
44 41 83 48 131 24z"
        />
        <path
          d="M2277 1099 c-71 -11 -128 -51 -176 -124 -23 -36 -26 -50 -26 -131 0
-82 3 -94 27 -132 40 -60 76 -90 138 -113 168 -64 350 67 350 251 0 65 -50
162 -102 200 -63 46 -132 62 -211 49z m102 -153 c38 -20 61 -58 61 -101 0
-120 -163 -158 -215 -49 -22 46 -14 92 23 126 44 41 83 48 131 24z"
        />
      </g>
    </svg>
  );
};

export default OrderIcon;

import React, { useState, useEffect } from "react";
import "./style.scss";
import { DOMAIN } from "../../../../config/env";
import ReturnBtn from "../../../../components/ui/button/ReturnBtn";
import ConfirmationModal from "../../../../components/ui/modal/confirmationModal";
import EngagementModal from "./addZoneModal";
import ImportExcelModal from "./ImportExcelModal";
import {
  getMagZoneByMag,
  getMagZoneUsl,
  addMagZoneUsl,
  deleteMagZoneUSl,
  getUslByDepts,
  addMagZone,
  updateMagZone,
  importExcelFile,
  deleteAllMagZoneUsl,
  duplicateMagZone,
  deleteMagZone,
} from "../../../../services/engagement_apis";
import useAuth from "../../../../hooks/useAuth";
import ZoneDistribution from "./ZoneDistribution";
import SearchByDepartment from "./SearchByDepartment";

const Index = () => {
  const { magasin } = useAuth();
  const { user } = useAuth();
  const [zones, setZones] = useState([]);
  const [selectedZone, setSelectedZone] = useState(null);
  const [uslData, setUslData] = useState([]);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isConfirmationModalEmptyOpen, setIsConfirmationModalEmptyOpen] =
    useState(false);
  const [isEngagementModalOpen, setIsEngagementModalOpen] = useState(false);
  const [isImportExcelModalOpen, setIsImportExcelModalOpen] = useState(false);
  const [initialModalData, setInitialModalData] = useState(null);
  const [searchResults, setSearchResults] = useState([]);

  //Charge les zones pour la liste déroulante
  useEffect(() => {
    const fetchZones = async () => {
      const zonesData = await getMagZoneByMag(magasin.mag_code);
      setZones(zonesData);
    };
    fetchZones();
  }, [magasin.mag_code]);

  //Charge mes USL a chaque changement de zone
  const handleZoneChange = async (event) => {
    const selectedZoneId = event.target.value;
    setSelectedZone(selectedZoneId);
    const uslResponse = await getMagZoneUsl(selectedZoneId);
    setUslData(uslResponse);
  };

  //Gère la supression d'usl pour une zone
  const handleDelete = (id) => {
    setRowToDelete(id);
    setIsConfirmationModalOpen(true);
  };
  const confirmDelete = async () => {
    const requestData = {
      id_mag_zone: parseInt(selectedZone),
      usl_code: rowToDelete,
    };
    try {
      await deleteMagZoneUSl(requestData);
      const updatedData = uslData.filter((row) => row.usl_code !== rowToDelete);
      setUslData(updatedData);
      setIsConfirmationModalOpen(false);
      setRowToDelete(null);
    } catch (error) {
      console.error("Erreur lors de l'appel à l'API:", error);
    }
  };
  //Bouton Vider la zone
  const handleEmptyZone = () => {
    setIsConfirmationModalEmptyOpen(true);
  };
  const confirmEmptyZone = async () => {
    try {
      await deleteAllMagZoneUsl({ id_mag_zone: parseInt(selectedZone) });
      setUslData([]);
      setIsConfirmationModalEmptyOpen(false);
    } catch (error) {
      console.error("Erreur lors de l'appel à l'API:", error);
    }
  };
  //Bouton supprimer la zone
  const handleDeleteZone = () => {
    setIsConfirmationModalEmptyOpen(true);
  };
  const confirmDeleteZone = async () => {
    try {
      await deleteMagZone({ id_mag_zone: parseInt(selectedZone) });
      // Mise à jour de la liste des zones en excluant la zone supprimée
      setZones((prevZones) =>
        prevZones.filter((zone) => zone.id_mag_zone !== parseInt(selectedZone))
      );
      setSelectedZone(null);
      setUslData([]);
      setIsConfirmationModalEmptyOpen(false);
    } catch (error) {
      console.error("Erreur lors de l'appel à l'API:", error);
    }
  };
  //Bouton Ajouter une zone
  const handleAddZone = async (formData) => {
    try {
      const newZoneResponse = await addMagZone({
        mag_code: magasin.mag_code,
        cmz_uti: user.name,
        cmz_libelle: formData.cmz_libelle,
      });

      const newZone = {
        id_mag_zone: newZoneResponse.id_mag_zone,
        mag_code: magasin.mag_code,
        cmz_libelle: formData.cmz_libelle,
      };
      setZones((prevZones) => [...prevZones, newZone]);
      setSelectedZone(newZoneResponse.id_mag_zone);
      const uslResponse = await getMagZoneUsl(newZone.id_mag_zone);
      setUslData(uslResponse);
    } catch (error) {
      console.error("Erreur lors de l'ajout de la nouvelle zone:", error);
    }
    setIsEngagementModalOpen(false);
  };
  const openAddZoneModal = () => {
    setInitialModalData(null);
    setIsEngagementModalOpen(true);
  };
  const handleCloseEngagementModal = () => {
    setIsEngagementModalOpen(false);
  };
  //Bouton Modifier nom de la zone
  const handleUpdateZone = async (formData) => {
    try {
      const updatedZoneResponse = await updateMagZone({
        id_mag_zone: selectedZone,
        cmz_libelle: formData.cmz_libelle,
      });
      setZones((prevZones) =>
        prevZones.map((zone) =>
          zone.id_mag_zone === updatedZoneResponse.id_mag_zone
            ? updatedZoneResponse
            : zone
        )
      );
    } catch (error) {
      console.error("Erreur lors de la modification de la zone:", error);
    }
    setIsEngagementModalOpen(false);
  };
  const openEditZoneModal = () => {
    if (selectedZone) {
      const zoneToEdit = zones.find(
        (zone) => parseInt(zone.id_mag_zone) === parseInt(selectedZone)
      );
      console.log(zoneToEdit);
      setInitialModalData(zoneToEdit);
      setIsEngagementModalOpen(true);
    }
  };
  //Ajoute une USL a la zone sélectionner
  const handleAdd = async (usl) => {
    try {
      const newUslForApi = {
        id_mag_zone: parseInt(selectedZone),
        usl_code: usl.usl_code,
      };

      await addMagZoneUsl(newUslForApi);

      const newUsl = {
        ...newUslForApi,
        usl: [usl],
      };
      setUslData((prevData) => [...prevData, newUsl]);
      setSearchResults((prevResults) =>
        prevResults.filter((r) => r.usl_code !== usl.usl_code)
      );
    } catch (error) {
      console.error("Erreur lors de l'appel à l'API:", error);
    }
  };
  //Execute l'api de recherche par departement
  const handleSearchByDepartments = async (departments) => {
    const requestData = {
      id_mag_zone: selectedZone,
      depts: departments.reduce((obj, dept, index) => {
        if (dept) obj[index + 1] = dept;
        return obj;
      }, {}),
    };
    const searchResponse = await getUslByDepts(requestData);
    setSearchResults(searchResponse);
  };
  //Bouton Import exel
  const handleImportExcel = async (file) => {
    try {
      const response = await importExcelFile(file, selectedZone);
      return response;
    } catch (error) {
      console.log(error);
      let errorMessage = "Erreur lors de l'importation du fichier Excel";
      if (error) {
        const apiError = error;
        if (apiError["Import incomplet "]) {
          const importError = apiError["Import incomplet "];
          errorMessage = `Import incomplet sur la ligne ${importError.lignes.join(", ")} (${importError.nb_lignes} ligne(s) affectée(s))`;
        }
      }

      throw new Error(errorMessage);
    }
  };

  const handleCloseImportExcelModal = async () => {
    setIsImportExcelModalOpen(false);
    if (selectedZone) {
      const uslResponse = await getMagZoneUsl(selectedZone);
      setUslData(uslResponse);
    }
  };
  const openImportExcelModal = () => {
    setIsImportExcelModalOpen(true);
  };
  //Bouton Duppliquer
  const handleDuplicateZone = async () => {
    console.log(selectedZone);
    try {
      const response = await duplicateMagZone(selectedZone);
      console.log(response);
      const newZone = {
        id_mag_zone: response.id_mag_zone,
        mag_code: response.newZone.mag_code,
        cmz_libelle: response.newZone.cmz_libelle,
      };
      setZones((prevZones) => [...prevZones, newZone]);

      setSelectedZone(response.id_mag_zone);

      const uslResponse = await getMagZoneUsl(response.id_mag_zone);
      setUslData(uslResponse);
    } catch (error) {
      console.error("Erreur lors de la duplication de la zone:", error);
    }
  };
  //Bouton Télécharger
  const downloadUrl = selectedZone
    ? `${DOMAIN.URL_WEB}export-cxMagZone/${selectedZone}`
    : "#";
  return (
    <div>
      <div className="CmNationale-titre">Zone(s) de distribution</div>
      <ReturnBtn />
      <ZoneDistribution
        zones={zones}
        selectedZone={selectedZone}
        handleZoneChange={handleZoneChange}
        uslData={uslData}
        handleDelete={handleDelete}
        openAddZoneModal={openAddZoneModal}
        openEditZoneModal={openEditZoneModal}
        openImportExcelModal={openImportExcelModal}
        handleDownload={downloadUrl}
        handleEmptyZone={handleEmptyZone}
        handleDeleteZone={handleDeleteZone}
        handleDuplicateZone={handleDuplicateZone}
      />
      <SearchByDepartment
        onAdd={handleAdd}
        selectedZone={selectedZone}
        onSearch={handleSearchByDepartments}
        searchResults={searchResults}
      />
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        onConfirm={confirmDelete}
        message="Êtes-vous sûr de vouloir supprimer cette opération ?"
      />
      <ConfirmationModal
        isOpen={isConfirmationModalEmptyOpen}
        onClose={() => setIsConfirmationModalEmptyOpen(false)}
        onConfirm={confirmEmptyZone}
        message="Êtes-vous sûr de vouloir vider cette zone ?"
      />
      <ConfirmationModal
        isOpen={isConfirmationModalEmptyOpen}
        onClose={() => setIsConfirmationModalEmptyOpen(false)}
        onConfirm={confirmDeleteZone}
        message="Êtes-vous sûr de vouloir supprimer cette zone ?"
      />
      <EngagementModal
        isOpen={isEngagementModalOpen}
        onClose={handleCloseEngagementModal}
        onConfirm={initialModalData ? handleUpdateZone : handleAddZone}
        initialData={initialModalData}
      />
      <ImportExcelModal
        isOpen={isImportExcelModalOpen}
        onClose={handleCloseImportExcelModal}
        onImport={handleImportExcel}
        selectedZone={selectedZone}
        zoneName={
          zones.find((zone) => zone.id_mag_zone === parseInt(selectedZone))
            ?.cmz_libelle
        }
      />
    </div>
  );
};

export default Index;

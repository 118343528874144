import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import EquipementPersonnel from "./EquipementPersonnel.png";
import PLVEquipementMagasin from "./PLVEquipementMagasin.png";
import ToutLesProduits from "./ToutLesProduits.png";
import { getRayon } from "../../../../services/boutique_api";
import useAuth from "../../../../hooks/useAuth";

const HomeBoutique = () => {
  const { magasin } = useAuth();
  const [rayons, setRayons] = useState([]);

  useEffect(() => {
    async function fetchRayons() {
      const data = await getRayon(magasin.mag_code);
      const allProductsCategory = {
        ra_code: 0,
        ra_libelle: "Tous les produits",
      };
      setRayons([allProductsCategory, ...data]);
    }

    fetchRayons();
  }, []);

  const getImageForRayon = (rayon) => {
    switch (rayon.ra_code) {
      case 2:
        return EquipementPersonnel;
      case 4:
        return PLVEquipementMagasin;
      default:
        return ToutLesProduits;
    }
  };

  return (
    <div>
      <h2 className="category-titre">CATEGORIE DE PRODUITS</h2>
      <div className="rayon-container">
        {rayons.map((rayon) => (
          <Link
            to={
              rayon.ra_code === 0 ? "produit/all" : `produit/${rayon.ra_code}`
            }
            key={rayon.ra_code}
          >
            <div className="rayon-card">
              <img src={getImageForRayon(rayon)} alt={rayon.ra_libelle} />
              <h3>{rayon.ra_libelle}</h3>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default HomeBoutique;

import React, { useState } from "react";
import "./style.scss";
import { useOutletContext } from "react-router-dom";
import useAuth from "../../../../../hooks/useAuth";
import {
  CLsendDevisDistriSpe,
  getChiffrementCL,
} from "../../../../../services/communication_apis";
import {
  dayOfWeek,
  formatDate,
  TODAY_DATE,
  toWeek,
} from "../../../../../utils/dates";
import Input from "../../../input/Input";
import Select from "../../../input/Select";
import Textarea from "../../../input/Textarea";
import CLdevisModal from "../../../modal/communication/CLdevisModal";
import validation from "./validation";
import moment from "moment";

const CLdistriSpeForm = () => {
  const cmdt_code_DEFAULT = 1;
  const cdsGroup = 2; // Group code for cds
  const { user, magasin } = useAuth();
  const [isValidForm, setIsValidForm] = useState(false); // Indicate if form is valid
  const [errors, setErrors] = useState({}); // Error bag for form
  const { zoneDistri } = useOutletContext();
  const [chiffrement, setChiffrement] = useState(0); // Calculated price for the quotation

  const [dateLiv, setDateLiv] = useState(new Date());

  const whiteData = {
    email: user.email,
    mag_nom: magasin.mag_nom,
    mag_ville: magasin.mag_ville,
    sem_distri: toWeek(TODAY_DATE),
    nom_doc: "",
    cmz_code: -1,
    cmdt_code: cmdt_code_DEFAULT,
    com: "",
    distri: 1,
    zone_distri: "",
    poids_uni: "",
    type_distri: "",
  };
  const [data, setData] = useState(whiteData);

  /**
   * calcul la date de livraison à effectuer sur la plateform mediapost
   */
  React.useEffect(() => {
    const daysGap = 14; // Maximum de jours a attendre pour la livraison
    const livDay = dayOfWeek(data.sem_distri);
    const livraisonMoment = moment(livDay, "DD/MM/YYYY");
    if (livraisonMoment.isValid()) {
      const livraison = livraisonMoment
        .subtract(daysGap, "days")
        .format("DD/MM/YYYY");
      console.log(livraison);
      setDateLiv(livraison);
    } else {
      console.error("Date invalide:", livDay);
    }
  }, [data.sem_distri]);

  // Change handler for inputs
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setData({
      ...data,
      [name]: value,
    });
  };

  /**
   * Fonction d'envoie du mail pour le devis
   */
  const handleSendDevis = async () => {
    const el1 = document.getElementById("cmz_code");
    let d = {
      ...data,
      date_liv: dateLiv,
      zone_distri: el1.options[el1.selectedIndex].text,
      sem_distri: data.sem_distri,
      distri: !!data.distri ? "oui" : "non",
    };
    await CLsendDevisDistriSpe(d);
    setData(whiteData);
  };

  /**
   * Validation de formulaire
   */
  const handleSubmit = (event) => {
    // Validation check for inputs
    const validate = async () => {
      const errorBag = await validation(data);
      setErrors(errorBag);
      const hasErrors = Object.keys(errorBag).length;
      if (hasErrors) {
        throw new Error("Champs invalides");
      }
      setIsValidForm(!hasErrors);
      const chiffrement = await getChiffrementCL(data.cmz_code, data.cmdt_code);
      setChiffrement(chiffrement);
    };
    try {
      event.preventDefault();
      setIsValidForm(false);
      validate();
      let dataTemp = data;

      // Récupère les libéllés des options sélectionnées
      if (magasin.mag_grp_cmx === cdsGroup) {
        const el2 = document.getElementById("distri");
        dataTemp = {
          ...dataTemp,
          type_distri: el2.options[el2.selectedIndex].text,
        };
      }
      const el1 = document.getElementById("cmdt_code");
      const el3 = document.getElementById("cmz_code");
      dataTemp = {
        ...dataTemp,
        poids_uni: el1.options[el1.selectedIndex].text,
        zone_distri: el3.options[el3.selectedIndex].text,
      };
      setData(dataTemp);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <form onSubmit={(data) => handleSubmit(data)} className="cl-dispe">
      <Select
        label="Semaine de distribution"
        name="sem_distri"
        value={data.sem_distri}
        onChange={(event) => handleChange(event)}
        error={errors.sem_distri}
      >
        {Array.from({ length: 52 }, (_, i) => i + 1).map((week) => (
          <option value={week}>semaine {week}</option>
        ))}
      </Select>

      <Input
        label="Nom du document"
        name="nom_doc"
        placeholder=" "
        value={data.nom_doc}
        onChange={(event) => handleChange(event)}
        error={errors.nom_doc}
      />

      <Select
        label="Poids unitaire d'un dépliant"
        name="cmdt_code"
        title="À demander à l'imprimeur, à ne pas confondre avec le poids
            du papier"
        id="cmdt_code"
        value={data.cmdt_code}
        onChange={(event) => handleChange(event)}
        error={errors.cmdt_code}
      >
        <option value={1}>0-10g</option>
        <option value={2}>11-20g</option>
        <option value={3}>21-40g</option>
        <option value={4}>41-70g</option>
      </Select>

      {/* Display only for CDS */}
      {magasin.mag_grp_cmx === cdsGroup && (
        <Select
          label="Distribution"
          name="distri"
          id="distri"
          value={data.distri}
          onChange={(event) => {
            handleChange(event);
          }}
          error={errors.distri}
        >
          <option value={1}>Distribution standard (GEO)</option>
          <option value={2}>
            Distribution habitat individuel (SELECT IMMO)
          </option>
        </Select>
      )}

      <div className="justify">
        <Select
          label="Zone de distribution"
          name="cmz_code"
          id="cmz_code"
          value={data.cmz_code}
          onChange={(event) => {
            handleChange(event);
          }}
          error={errors.cmz_code}
        >
          <option value={-1}> -- Selectionner -- </option>
          {zoneDistri.length &&
            zoneDistri.map((zone) => {
              return (
              <option key={zone.id_mag_zone} value={zone.id_mag_zone}>
                {zone.cmz_libelle}
              </option>
            )}
            )}
        </Select>
      </div>
      {magasin.mag_grp_cmx === cdsGroup && (
        <>
          <p className="warning">
            <span> IMPORTANT : </span> Ce devis est établi sur la base tarifaire
            et la base de diffusion de l'année en cours (janvier à décembre). La
            société Médiapost réactualise régulièrement sa base de diffusion (en
            janvier et juillet) et sa base tarifaire le 1er janvier. Les zones
            peuvent amenées à évoluer afin de garantir une qualité de diffusion
            optimale. Notre devis sera donc ajusté en conséquence.
          </p>
        </>
      )}
      <Input
        type="email"
        label="Email"
        name="email"
        value={data.email}
        onChange={(event) => handleChange(event)}
        error={errors.email}
      />

      <Textarea
        label="Commentaire"
        rows="6"
        maxLength="255"
        placeholder=" "
        name="com"
        value={data.com}
        onChange={(event) => handleChange(event)}
        error={errors.com}
      />

      <div className="cl-dispe__action">
        <CLdevisModal
          isValidForm={isValidForm}
          onValidation={handleSendDevis}
          email={data.email}
        >
          <p className="text-danger">
            ATTENTION ! prise de RDV obligatoire pour la livraison de vos
            dépliants.
          </p>
          <ul>
            {/* <li>- Montant estimé : {data.chiffrement}</li> */}
            <li>
              - Livraison sur la plateforme Médiapost à effectuer avant le :{" "}
              {dateLiv}
            </li>
            <li>
              - La référence à inscrire sur la fiche palette :
              <br />
              <ul className="reference-palette">
                <li>
                  Magasin : {magasin.mag_nom} - {magasin.mag_ville}
                </li>
                <li>Nom du document : {data.nom_doc}</li>
                <li>
                  Semaine de distribution souhaitée : semaine {data.sem_distri}
                </li>
              </ul>
            </li>
          </ul>
        </CLdevisModal>
      </div>
    </form>
  );
};

export default CLdistriSpeForm;

import * as Yup from 'yup';
import { ERROR_MESSAGE } from '../../../../config/constants';

const requiredFields = [
  'mag_nom',
  'mag_contact',
  'mag_tel',
  'mag_ville',
  'mag_mail',
];

const genericRequiredFieldRules = {};

requiredFields.forEach((fieldName) => {
  genericRequiredFieldRules[fieldName] = Yup.string()
    .nullable(true)
    .required(ERROR_MESSAGE.REQUIRED);
});

const fieldRules = { ...genericRequiredFieldRules };
const schema = Yup.object().shape(fieldRules);

// Fonction de validation des données du formulaire
const validation = async (data) => {
  const errorBag = {};
  await schema.validate(data, { abortEarly: false }).catch((err) => {
    err?.inner.forEach((res) => {
      errorBag[res.path] = res.message;
    });
  });
  return errorBag;
};

export default validation;

// src/components/Distribution.js
import React, { useContext } from "react";
import { OperationContext } from "../operationContext";
import DistributionSkeleton from "./skeleton/distributionSkeleton";

const Distribution = () => {
  const {
    operationsData,
    formState,
    handleInputChange,
    magOpeData,
    isLoading,
  } = useContext(OperationContext);

  if (formState.mo_digital === "1") {
    return null;
  }
  if (isLoading) {
    return <DistributionSkeleton />;
  }
  const isDisabled = formState.id_mag_cmd > 0;
  return (
    <div className="block medium distribution">
      <h3>Distribution</h3>
      <div className="selection-container">
        <div className="left-selection">
          <div className="form-group">
            <label htmlFor="exemplaires-caisse">Exemplaires caisse</label>
            <select
              id="exemplaires-caisse"
              className="styled-select"
              name="mo_qte_caisse"
              value={formState.mo_qte_caisse}
              onChange={handleInputChange}
              disabled={isDisabled}
            >
              <option value={0}>0</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={150}>150</option>
              <option value={200}>200</option>
            </select>
            <label htmlFor="distribution-select">
              Sélectionnez une distribution
            </label>
            <select
              id="distribution-select"
              className="styled-select"
              name="mo_distribution"
              value={formState.mo_distribution}
              onChange={handleInputChange}
              disabled={isDisabled}
            >
              <option value="0" disabled>
                Choisir une distribution
              </option>
              {operationsData.ope_mediaposte === 1 && (
                <option value="1">Médiaposte</option>
              )}
              {operationsData.ope_diffusion_magasin === 1 && (
                <option value="2">Livraison au Magasin</option>
              )}
              {operationsData.ope_diffusion_autre === 1 && (
                <option value="3">Autre</option>
              )}
            </select>
          </div>
        </div>
        <div className="right-selection">
          <div className="form-group">
            <label htmlFor="lien-depliant">
              Lien dépliant feuilletable personnalisé
            </label>
            <select
              id="lien-depliant"
              className="styled-select"
              name="mo_lien_personnalisable"
              value={formState.mo_lien_personnalisable}
              onChange={handleInputChange}
              disabled={isDisabled}
            >
              <option value="0">Non</option>
              <option value="1">Oui</option>
            </select>

            {formState.mo_distribution === "1" && (
              <>
                <label htmlFor="zone-select">
                  Sélectionnez une zone de distribution
                </label>
                <select
                  id="zone-select"
                  className="styled-select"
                  name="id_mag_zone"
                  value={formState.id_mag_zone}
                  onChange={handleInputChange}
                  disabled={isDisabled}
                >
                  <option value="0" disabled>
                    Choisir une zone
                  </option>
                  {magOpeData.cx_mag_zone.map((zone) => (
                    <option key={zone.id_mag_zone} value={zone.id_mag_zone}>
                      {zone.cmz_libelle}
                    </option>
                  ))}
                </select>
                <div>
                  <label htmlFor="autre-exemplaires">
                    Nombre d'exemplaires
                  </label>
                  <input
                    id="autre-exemplaires"
                    name="mo_dist_qte"
                    value={formState.mo_dist_qte}
                    disabled={isDisabled}
                  />
                </div>
              </>
            )}
            {formState.mo_distribution === "2" && (
              <div>
                <label htmlFor="autre-exemplaires">Nombre d'exemplaires</label>
                <input
                  id="autre-exemplaires"
                  name="mo_dist_qte"
                  type="number"
                  value={formState.mo_dist_qte}
                  onChange={handleInputChange}
                  disabled={isDisabled}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="footer-section">
        {formState.mo_distribution === "3" && (
          <>
            <div>
              <label htmlFor="autre-exemplaires">Nombre d'exemplaires</label>
              <input
                id="autre-exemplaires"
                name="mo_dist_qte"
                type="number"
                value={formState.mo_dist_qte}
                onChange={handleInputChange}
                disabled={isDisabled}
              />
            </div>
            <div>
              <label htmlFor="autre-adresse">Adresse de livraison</label>
              <textarea
                id="autre-adresse"
                name="mo_adr_livraison"
                value={formState.mo_adr_livraison}
                onChange={handleInputChange}
                placeholder="Entrez l'adresse ici..."
                disabled={isDisabled}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Distribution;

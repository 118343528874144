import React from "react";
import "./style.scss";
import HomeLayout from "../../../components/layout/HomeLayout";
import ChronosList from "./../../../components/ui/lists/ChronosList";
import { Link } from "react-router-dom";
import { PATH } from "../../../config/constants";
import ContratCarousel from "../../../components/ui/carousels/ContratCarousel";
import PlanComAccueilCard from "../../../components/ui/cards/PlanComAccueilCard";
import useAuth from "../../../hooks/useAuth";
import SharpButton from "../../../components/ui/button/SharpButton";
import { DOMAIN } from "../../../config/env";

const Home = () => {
  const { magasin } = useAuth();

  return (
    <HomeLayout>
      <div className="home">
        {magasin.mag_salon !== 0 && (
          <section className="info-banner">
            Salon inédis les 8 & 9 novembre 2023 à Cournon d'Auvergne
            <div className="info-banner-action">
              <a
                href={`${DOMAIN.URL}_fichier/_salon/Inscription AFFILIES.pdf`}
                target="_blank"
                rel="noreferrer"
              >
                <SharpButton libelle="En savoir plus" color="color-secondary" />
              </a>
              <a
                href={`${DOMAIN.URL}_fichier/_salon/dossier salon.pdf`}
                target="_blank"
                rel="noreferrer"
              >
                <SharpButton
                  libelle="Télécharger le dossier salon"
                  color="color-secondary"
                />
              </a>
              {magasin.mag_salon === 1 && (
                <Link to={PATH.SALON_INSCRIPTION}>
                  <SharpButton libelle="Inscrivez-vous ici >" />
                </Link>
              )}
            </div>
          </section>
        )}
        <section className="home-infos">
          <ChronosList />
          <div className="home-infos-droite">
            <PlanComAccueilCard />
            <Link to={PATH.BDC_PROSPECTUS_PAGE} className="link-fill">
              Bons de commandes produits dépliants
            </Link>
          </div>
        </section>
        <section className="contrats-section">
          <ContratCarousel />
        </section>
      </div>
    </HomeLayout>
  );
};

export default Home;

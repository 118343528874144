import React from "react";

const Modifier = () => {
  return (
    <div className="valider-container">
      <p>Votre repiquage est en cours de modification.</p>
      <p>
        Pour toute question, veuillez vous rapprocher de votre interlocuteur
        Inedis habituel
      </p>
    </div>
  );
};

export default Modifier;

import React from "react";
import { Outlet } from "react-router-dom";
import BoutiqueLayout from "../../../components/layout/BoutiqueLayout";

const Boutique = () => {
  return (
    <BoutiqueLayout>
      <Outlet />
    </BoutiqueLayout>
  );
};

export default Boutique;

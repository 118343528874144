import React from "react";
import "./style.scss";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const ConfirmationModal = ({ isOpen, onClose, onConfirm, message }) => {
  if (!isOpen) return null;

  return (
    <div className="confirmation-modal-overlay">
      <div className="confirmation-modal-content">
        <div className="confirmation-modal-header">
          <div className="confirmation-modal-title">
            <WarningAmberIcon color="error" />
            <h2>Confirmation</h2>
          </div>

          <button className="confirmation-close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="confirmation-modal-body">{message}</div>
        <div className="confirmation-modal-footer">
          <button className="confirmation-cancel-button" onClick={onClose}>
            Annuler
          </button>
          <button className="confirmation-delete-button" onClick={onConfirm}>
            Supprimer
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;

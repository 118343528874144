import { Link } from "react-router-dom";
import AcceuilBannerCarousel from "../../ui/carousels/AcceuilBannerCarousel";
import useAuth from "../../../hooks/useAuth";
import { PATH , RESSOURCE } from "../../../config/constants";
import { DOMAIN } from "../../../config/env";

import "./style.scss"

const FideliteLayout = ({ children }) => {

    const { magasin } = useAuth()

    return (
        <>
            <AcceuilBannerCarousel />
            <div className="fidelity-container">
                <div className="fidelity-main">
                    {children}
                </div>
                <div className="fidelity-aside">
                    <div className="fidelity-aside-button-container">
                        <Link to={PATH.FIDELITY_REGISTRATION} className="button-primary-type">Souscrire au programme de fidélité</Link>
                        <a href={`${DOMAIN.URL_WEB}${RESSOURCE.DOCS_FID}TARIF-FID-2025-ADELYA.pdf`} className="button-primary-type" target="_blank">Grille tarifaire fidélité</a>
                        {
                            magasin.ens_code !== "TN" ? <>
                                {
                                    magasin.ens_code && magasin.ens_code == "FRU" && <a href="https://heyzine.com/flip-book/6511d04341.html" target="_blank" className="button-primary-type">Téléchargement plaquette presentation</a>
                                }
                                {
                                    magasin.ens_code && magasin.ens_code == "CDS" && <a href="https://heyzine.com/flip-book/a3d0f75af2.html" className="button-primary-type" target="_blank">Téléchargement plaquette presentation</a>
                                }

                            </>

                                :
                                <div className="spacer-button">
                                    <a href="https://heyzine.com/flip-book/870970f251.html" className="button-primary-type" target="_blank">Téléchargement plaquette presentation prochasse</a>
                                    <a href="https://heyzine.com/flip-book/fc7da0b474.html" className="button-primary-type" target="_blank">Téléchargement plaquette presentation propeche</a>
                                </div>
                        }

                        {
                            magasin.ens_code == 'TN' ?
                            <a href={`${DOMAIN.URL_WEB}${RESSOURCE.DOCS_FID}fid-client-tn.pdf`} className="button-primary-type" target="_blank">Téléchargement Questionnaire Ouverture carte fidélité</a> :
                            <a href={`${DOMAIN.URL_WEB}${RESSOURCE.DOCS_FID}fid-client-lisa-cds.pdf`} className="button-primary-type" target="_blank">Téléchargement Questionnaire Ouverture carte fidélité</a>
                        }
                    </div>



                    <div>
                        <Link to={PATH.CMNATIONALE} className="button-secondary-type">S'engager sur les opérations nationales</Link>
                    </div>

                    <div className="fidelity-aside-address-container">
                        <p>Une question ou un problème technique contactez le service client ADELYA</p>

                        <p>Au : 05 47 74 83 71</p>

                        <p>support@adelya.com</p>

                    </div>
                </div>
            </div>
        </>
    )

}

export default FideliteLayout;
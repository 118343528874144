import React from "react";
import "./style.scss";
import { useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import {
  getProductsCommand,
  setCommandInformations,
} from "../../../../services/communication_apis";
import ReturnBtn from "../../../../components/ui/button/ReturnBtn";
import { formatDate, formatPrix } from "../../../../utils/dates";
import BDCfournisseurCommandCard from "../../../../components/ui/cards/communication/BDCfournisseurCommandCard";
import BDCcommandeTable from "../../../../components/ui/tables/communication/BDCcommandeTable";
import BDCprintCommandButton from "../../../../components/ui/button/BDCprintCommandButton";
import BDCExcelCommandButton from "../../../../components/ui/button/BDCExcelCommandButton";
import BDCdeleteCommandeButton from "../../../../components/ui/button/BDCdeleteCommandeButton";
import LoaderComponent from "../../../../components/ui/loader/LoaderComponent";

const BDCcommande = () => {
  const params = useParams(); // Param in url
  const [loading, setLoading] = useState(true); // Indicate the call has not finished. Default to true

  const prospectusId = params.prospectusId; // Prospectus ID (pspc_prospectus)
  const { prospectusList } = useOutletContext(); // List of all prospectus
  const [prospectus, setProspectus] = useState({}); // Actual prospectus

  const codeFournisseur = params.codeFournisseur; // Code fournisseur (con_code)
  const { fournisseursCommandsList } = useOutletContext(); // List of all fournisseurs with command
  const [fournisseur, setFournisseur] = useState({}); // Actual fournisseur command

  const [products, setProducts] = useState({}); // Products command
  const [totalCommandPrice, setTotalCommandPrice] = useState(0); // Total command price

  // User information for this command
  const [userInfo, setUserInfo] = useState({
    bi_commentaire: "",
    bi_adr_liv: "",
    bi_adr_fact: "",
    bi_semaine_liv: "",
  });

  // Save data of user information on blur for the fournisseur command
  const handleBlurUserInfo = async () => {
    // Save data in DB
    setCommandInformations(
      fournisseur.bi_code,
      userInfo.bi_commentaire,
      userInfo.bi_adr_liv,
      userInfo.bi_adr_fact,
      userInfo.bi_semaine_liv
    );
    const codeCommand = fournisseur.bi_code; // code of the command

    // Get fournisseur of the command
    const found = fournisseursCommandsList.find(
      (f) => f.bi_code === codeCommand
    );
    const index = fournisseursCommandsList.indexOf(found);

    // Update fournisseur command with user information
    if (index > -1) {
      fournisseursCommandsList[index].bi_commentaire = userInfo.bi_commentaire;
      fournisseursCommandsList[index].bi_adr_liv = userInfo.bi_adr_liv;
      fournisseursCommandsList[index].bi_adr_fact = userInfo.bi_adr_fact;
      fournisseursCommandsList[index].bi_semaine_liv = userInfo.bi_semaine_liv;
    }
  };

  // Save user information data on change
  const handleOnChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setUserInfo({
      ...userInfo,
      [name]: value,
    });
  };

  /**
   * Refresh quantities when an information of ordered product is modified
   */
  const handleChangeProduct = () => {
    try {
      getProductsCommand(fournisseur.bi_code).then((res) => {
        setProducts(res.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Set user information on fournisseur update
  React.useEffect(() => {
    try {
      const userInfoToSet = {
        bi_commentaire: fournisseur.bi_commentaire ?? "",
        bi_adr_liv: fournisseur.bi_adr_liv ?? "",
        bi_adr_fact: fournisseur.bi_adr_fact ?? "",
        bi_semaine_liv: fournisseur.bi_semaine_liv ?? "",
      };
      setUserInfo(userInfoToSet);
    } catch (err) {
      console.log(err);
    }
  }, [fournisseur]);

  // Refresh total Price
  React.useEffect(() => {
    if (products.length) {
      let total = 0;
      products.map((product) => {
        if (product.bil_paht && product.bil_qte) {
          console.log(product);
          return (total += product.bil_paht * product.bil_qte);
        } else {
          return 0;
        }
      });
      setTotalCommandPrice(total);
    }
  }, [products]);

  // Set data
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        // Get actual fournisseur
        if (fournisseursCommandsList.length >= 1) {
          const fournisseurToFind = fournisseursCommandsList.find(
            (f) =>
              f.con_code === parseInt(codeFournisseur) &&
              f.psp_cprospectus === parseInt(prospectusId)
          );
          setFournisseur(fournisseurToFind);

          // Get all products releated to this command
          if (fournisseurToFind) {
            await getProductsCommand(fournisseurToFind.bi_code).then((res) => {
              setProducts(res.data);
            });
          }
        }

        // Get actual prospectus
        if (prospectusList.length >= 1) {
          const prospectusFind = prospectusList.find(
            (p) => p.psp_cprospectus === parseInt(prospectusId)
          );
          setProspectus(prospectusFind);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [codeFournisseur, fournisseursCommandsList, prospectusId, prospectusList]);

  return (
    <div className="command-fourn-recap__container">
      <ReturnBtn />
      {!loading ? (
        <>
          <header className="command-fourn-recap__head">
            <div className="prospectus-title">
              <div>
                {prospectus.psp_libelle}
                <ul className="prospectus-title__date">
                  {!prospectus.psp_dn1_debut?.startsWith("0000") ? (
                    <li>
                      <span> Date nationale 1 </span> du{" "}
                      {formatDate(prospectus.psp_dn1_debut)} au{" "}
                      {formatDate(prospectus.psp_dn1_fin)}
                    </li>
                  ) : (
                    <></>
                  )}
                  {!prospectus.psp_dn2_debut?.startsWith("0000") ? (
                    <li>
                      <span> Date nationale 2 </span> du{" "}
                      {formatDate(prospectus.psp_dn2_debut)} au{" "}
                      {formatDate(prospectus.psp_dn2_fin)}
                    </li>
                  ) : (
                    <></>
                  )}
                </ul>
              </div>
            </div>
          </header>

          {fournisseur ? (
            <>
              <div>
                <BDCfournisseurCommandCard fournisseur={fournisseur} />
              </div>

              <div className="command-table__wrap">
                <BDCcommandeTable
                  products={products}
                  onChangeProduct={handleChangeProduct}
                />
              </div>
              <div className="command-action">
                <div className="command-total-price">
                  <span> total commande </span>
                  {formatPrix(totalCommandPrice)}
                </div>
                <BDCprintCommandButton fournisseur={fournisseur} />
                <BDCExcelCommandButton fournisseur={fournisseur} />
                <BDCdeleteCommandeButton
                  codeCommand={fournisseur.bi_code}
                  path={-1}
                />
              </div>

              <div className="information-title">
                <h3> Informations commande </h3>
              </div>
              <footer className="command-table__footer">
                <div className="command-form">
                  <div className="command-form__commentary">
                    <label className="text-area">
                      <textarea
                        className="text-area__field"
                        rows="6"
                        maxLength="255"
                        placeholder=" "
                        name="bi_commentaire"
                        value={userInfo.bi_commentaire ?? ""}
                        onChange={(event) => handleOnChange(event)}
                        onBlur={() => handleBlurUserInfo()}
                      />
                      <span className="input__label">Commentaire</span>
                    </label>
                  </div>
                  <div className="command-form__liv">
                    <label className="input">
                      <input
                        className="input__field"
                        placeholder=" "
                        name="bi_adr_liv"
                        value={userInfo.bi_adr_liv}
                        onChange={(event) => handleOnChange(event)}
                        onBlur={() => handleBlurUserInfo()}
                      />
                      <span className="input__label">
                        Adresses de livraison
                      </span>
                    </label>
                  </div>
                  <div className="command-form__fact">
                    <label className="input">
                      <input
                        className="input__field"
                        placeholder=" "
                        name="bi_adr_fact"
                        value={userInfo.bi_adr_fact}
                        onChange={(event) => handleOnChange(event)}
                        onBlur={() => handleBlurUserInfo()}
                      />
                      <span className="input__label">
                        Société de facturation
                      </span>
                    </label>
                  </div>
                  <div className="command-form__sem">
                    <label className="input">
                      <input
                        className="input__field"
                        placeholder=" "
                        name="bi_semaine_liv"
                        value={userInfo.bi_semaine_liv}
                        onChange={(event) => handleOnChange(event)}
                        onBlur={() => handleBlurUserInfo()}
                      />
                      <span className="input__label">
                        Semaine de livraison souhaitée
                      </span>
                    </label>
                  </div>
                </div>
              </footer>
            </>
          ) : (
            <>Aucune commande pour ce fournisseur</>
          )}
        </>
      ) : (
        <LoaderComponent />
      )}
    </div>
  );
};

export default BDCcommande;

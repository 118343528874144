import { DOMAIN } from "../config/env";
import api from "./apiClient";

/** Récupère tout les rayon de la boutique */
export async function getRayon (mag_code) {
  let rayons = {};

  await api.get(DOMAIN.URL_API + "boutique/rayon/" + mag_code).then((res) => {
    rayons = res.data;
  });
  return rayons;
}
/** Récupère tous les produits */
export async function getAllProduits (mag_code) {
  let produits = {};
  await api
    .get(DOMAIN.URL_API + "boutique/produit/" + mag_code + "/rayon")
    .then((res) => {
      produits = res.data;
    });
  return produits;
}
/** Récupère les produits selon le rayon  */
export async function getProduitByRayon (rayon, mag_code) {
  let produits = {};
  await api
    .get(DOMAIN.URL_API + "boutique/produit/" + mag_code + "/rayon/" + rayon)
    .then((res) => {
      produits = res.data;
    });
  return produits;
}
/** Récupère les produits selon l'id */
export async function getProduitById (idProduit) {
  let produits = {};
  await api
    .get(DOMAIN.URL_API + "boutique/produit/" + idProduit)
    .then((res) => {
      produits = res.data;
    });
  return produits;
}
// Call api to send devis by email
export async function BoutiqueCommandeMail (data) {
  try {
    const response = await api.post(DOMAIN.URL_API + "boutique/commande/mail", {
      data: { ...data },
    });

    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'appel API:", error);
    throw error;
  }
}

export async function BoutiqueAddCommande (data) {
  try {
    const response = await api.post(DOMAIN.URL_API + "boutique/commande", {
      data: { ...data },
    });

    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'appel API:", error);
    throw error;
  }
}
/** Récupère toutes les commandes selon le magasin  */
export async function getCommandeByMagasin (mag_code) {
  let produits = {};
  await api
    .get(DOMAIN.URL_API + "boutique/commande/" + mag_code)
    .then((res) => {
      produits = res.data;
    });
  return produits;
}

export const getPanierUser = async (mag_code) => {
  try {
    const response = await api.get(DOMAIN.URL_API + "boutique/panier", {
      params: { mag_code },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const storePanier = async (item) => {
  try {
    const response = await api.post(DOMAIN.URL_API + "boutique/panier/add", item);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deletePanier = async (cartItemId, mag_code) => {
  try {
    const response = await api.delete(
      DOMAIN.URL_API + `boutique/panier/${cartItemId}`,
      {
        params: { mag_code },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};



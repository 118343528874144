import React from "react";
import Drawer from "../../ui/drawers/Drawer";
import "./style.scss";
import { useColoredLinks } from "./links";
import AcceuilBannerCarousel from "../../ui/carousels/AcceuilBannerCarousel";

const BoutiqueLayout = ({ children }) => {
  return (
    <div className="page-banner-layout">
      <header className="page-banner-layout__banner">
        <AcceuilBannerCarousel />
      </header>
      <div className="page-banner-layout__bg"></div>
      <div className="page-banner-layout__outlet">
        <div className="home-layout">
          <div className="home-layout__content">
            {/* <div className="promo-container">
              <div className="promo-discount">-5%</div>
              <div className="promo-description">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                blandit id tellus vitae luctus. Curabitur iaculis dolor in diam
                maximus, ac condimentum massa rutrum. Vivamus sed risus ipsum.{" "}
              </div>
            </div> */}
            {children}
          </div>
          <Drawer links={useColoredLinks()} />
        </div>
      </div>
    </div>
  );
};

export default BoutiqueLayout;

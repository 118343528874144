import { PATH } from "../../../config/constants";
import BasketIcon from "../../ui/icons/BasketIcon";
import EuroIcon from "../../ui/icons/EuroIcon";
import FactoryIcon from "../../ui/icons/FactoryIcon";
import FileArrowDownload from "../../ui/icons/FileArrowDownload";
import PhoneIcon from "../../ui/icons/PhoneIcon";
import StoreFrontIcon from "../../ui/icons/StoreFrontIcon";

const links = (magasin) => [
  {
    to: PATH.UP_SELECTION_INFO.replace(':idMetier','0'),
    libelle: 'FOURNISSEURS - TOUS LES UNIVERS',
    type: 'filled',
    icon: <FactoryIcon height={50} width={50} />

  },
  {
    to: PATH.BOUTIQUE,
    libelle: "BOUTIQUE EN LIGNE",
    type: "filled",
    icon: <BasketIcon height={50} width={50} />,
  },
  {
    to: PATH.MISES_A_JOUR,
    libelle: "MISE A JOUR TARIFS",
    type: "filled",
    icon: <EuroIcon height={50} width={50} />,
  },
  {
    to: PATH.PAM,
    libelle: "PAM",
    sublibelle: "Plan animation magasin",
    type: "filled",
    icon: <StoreFrontIcon height={50} width={50} />,
  },
  {
    to: PATH.TELECHARGEMENT_COM,
    libelle: "Documents à télécharger",
    type: "filled",
    icon: <FileArrowDownload height={50} width={50} />,
  },
  {
    to: PATH.CONTACT_PAGE,
    libelle: "Contacter Inédis",
    type: "filled",
    icon: <PhoneIcon height={50} width={50} />,
  },
];

export const coloredLinks = (magasin) =>
  links(magasin).map((link, index) => {
    const colorClass =
      index % 2 === 0 ? "filled-link--blue" : "filled-link--green";
    return {
      ...link,
      class: `${link.class} ${colorClass}`,
    };
  });

import React, { useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import './style.scss';
import { PATH } from '../../../../config/constants';
import FPColorCardLink from "../../../../components/ui/cards/_fournisseurs_produits/FPoptionCard";


/**
 * Options disponibles a selectionner
 */
const AEselectionSection = () => {
  const { sectionsList } = useOutletContext();

  const optionList = useMemo(() => {
    // On ne prend que les sections avec des prestataires
    const filteredSections = sectionsList.filter(
      (section) => section.prestataires.length
    );
    return filteredSections.map((section, index) => ({
      to: `${PATH.AE_SECTIONS}/${section.sec_id}`,
      className:
        index % 2 === 0 ? 'ae-option--blue' : 'ae-option--green',
      text: section.sec_nom,
    }));
  }, [sectionsList]);

  return (
    <div className="ae-select-opt">
      <h3 className="ae-select-opt__title">
        Coordonnées des fournisseurs pour l'agencement et
        l'exploitation de votre magasin
      </h3>
      <div className="ae-select-opt__content">
        <div className="ae-select-opt__option-list">
          {optionList.map((option) => (
            <FPColorCardLink
              key={option.to}
              to={option.to}
              className={option.className}
            >
              {option.text}
            </FPColorCardLink>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AEselectionSection;

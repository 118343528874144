import React, { useState } from 'react';
import './style.scss';
import Input from '../../../input/Input';
import Select from '../../../input/Select';
import Textarea from '../../../input/Textarea';
import useAuth from '../../../../../hooks/useAuth';
import { calculMailingCourrier } from '../../../../../utils/CL_devis_calculs';
import { useOutletContext } from 'react-router-dom';
import { CLsendDevisMailing } from '../../../../../services/communication_apis';
import { formatDate, TODAY_DATE } from '../../../../../utils/dates';
import validation from './validation';
import CLdevisModal from '../../../modal/communication/CLdevisModal';

const CLfidMailingCourrierForm = () => {
  const [isValidForm, setIsValidForm] = useState(false);
  const [errors, setErrors] = useState({});
  const { potentiel } = useOutletContext();
  const { user, magasin } = useAuth();

  const [tarification, setTarification] = useState({
    service: 'courrier', // libelle du service
    frais_fixes: 150, // Montant de frais fixe
    cout_impression_1: 0.299, // Cout unitaire d'impression 1
    cout_impression_2: 0.275, // Cout unitaire d'impression 1
    traitement_postal: 0.1, // Cout du traitement postal unitaire
    affranchissement_1: 0.4, // Cout affranchissement 1
    affranchissement_2: 0.388, // Cout affranchissement 2
    seuil_commande: 1000, // Seuil de séparation entre les couts
  });

  const whiteData = {
    mag_nom:magasin.mag_nom,
    mag_ville:magasin.mag_ville,
    email: user.email,
    date_valid_deb: TODAY_DATE,
    date_valid_fin: TODAY_DATE,
    com: '',
    offre: '',
    ciblage: 0,
  };

  const [data, setData] = useState(whiteData);

  React.useEffect(() => {
    if (potentiel) {
      setTarification({
        ...tarification,
        quantite: potentiel.mp_qte_email, // Quantite en fonction du magasin
        maj: potentiel.mp_date_maj, // Date de mise à jour de la quantite
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [potentiel]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validation check for inputs
    const validate = async () => {
      const errorBag = await validation(data);
      setErrors(errorBag);
      const hasErrors = Object.keys(errorBag).length;
      if (hasErrors) {
        throw new Error('Champs invalides');
      }
      setIsValidForm(!hasErrors);
      // Chiffrage uniquement si première options selectionnée
      let tarifs = { ...tarification, montant: null };
      parseInt(data.ciblage) === 0 &&
        (tarifs = {
          ...tarification,
          montant: calculMailingCourrier(tarification),
        });
      setTarification(tarifs);
    };
    try {
      setIsValidForm(false);
      validate();
    } catch (err) {
      console.log(err);
    }
  };

  // Send mail devis on validation
  const handleSendDevis = async () => {
    const el1 = document.getElementById('ciblage');

    let d = {
      ...data,
      date_valid_deb: formatDate(data.date_valid_deb),
      date_valid_fin: formatDate(data.date_valid_fin),
      tarification: tarification,
      ciblage: el1.options[el1.selectedIndex].text,
    };
    await CLsendDevisMailing(d);
    setData(whiteData);
  };
  
  return (
    <form
      onSubmit={(data) => handleSubmit(data)}
      className="cl-mailing"
    >
      <div className="date-validite">
        <Input
          type="date"
          min={TODAY_DATE}
          label="Début date de validité"
          name="date_valid_deb"
          value={data.date_valid_deb}
          onChange={(event) => handleChange(event)}
          error={errors.date_valid_deb}
        />
        <Input
          type="date"
          min={data.date_valid_deb}
          label="Fin date de validité"
          name="date_valid_fin"
          value={data.date_valid_fin}
          onChange={(event) => handleChange(event)}
          error={errors.date_valid_fin}
        />
      </div>
      <Textarea
        label="Offre"
        rows="2"
        maxLength="255"
        placeholder="Indiquez le texte souhaité."
        name="offre"
        value={data.offre}
        onChange={(event) => handleChange(event)}
        error={errors.offre}
      />
      <Select
        label="Ciblage"
        name="ciblage"
        id="ciblage"
        value={data.ciblage}
        onChange={(event) => handleChange(event)}
        error={errors.ciblage}
      >
        <option value={0}>Clients actifs 12 derniers mois</option>
        <option value={1}>Tous clients (sur devis)</option>
        <option value={2}>Autres ciblages (sur devis)</option>
      </Select>
      <div>
        <h4> Rappel des tarifs HT :</h4>
        <ul>
          <li>Frais fixes : {tarification.frais_fixes}&nbsp;€</li>
          <li>
            Impression : {tarification.cout_impression_1}&nbsp;€ /
            {tarification.service} (&#60;1000){' '}
            {tarification.cout_impression_2}&nbsp;€ /
            {tarification.service} (&#62;1000)
          </li>
          <li>
            Traitement postal : {tarification.traitement_postal}
            &nbsp;€ /{tarification.service}
          </li>
          <li>
            Affranchissement : {tarification.affranchissement_1} /
            {tarification.service}
            &nbsp;€ (&#60;1000) ou {tarification.affranchissement_2}
            &nbsp;€ /{tarification.service} (&#62;1000)
          </li>
          <br />
        </ul>
      </div>
      <Input
        type="email"
        label="Email"
        name="email"
        value={data.email}
        onChange={(event) => handleChange(event)}
        error={errors.email}
      />
      <Textarea
        label="Commentaire"
        rows="5"
        maxLength="255"
        placeholder=" "
        name="com"
        value={data.com}
        onChange={(event) => handleChange(event)}
        error={errors.com}
      />
      <div className="action-btn">
        <CLdevisModal
          isValidForm={isValidForm}
          onValidation={handleSendDevis}
          tarification={tarification}
          email={data.email}
        />
      </div>
    </form>
  );
};

export default CLfidMailingCourrierForm;

import "./style.scss"

import useAuth from "../../../hooks/useAuth";
import FideliteLayout from "../../../components/layout/FideliteLayout";

import blocFidLISA1 from './images/bloc-fid-jardi-lisa1.jpg';
import blocFidLISA2 from './images/bloc-fid-jardi-lisa2.jpg';

import blocFidCDS1 from './images/bloc-fid-cds1.jpg';
import blocFidCDS2 from './images/bloc-fid-cds2.jpg';

import blocFidTN1 from './images/bloc-fid-tn1.jpg';
import blocFidTN2 from './images/bloc-fid-tn2.jpg';
import { useEffect, useState } from "react";

const Fidelite = () => {

    const { magasin } = useAuth()

    const [popupHide, setPopupHide] = useState(false)
    const [popupType, setPopupType] = useState(null)

    return (
        <div>
            <FideliteLayout>
                <section>
                    <h3 className="fidelity-title"><span>Pourquoi</span> un <span>programme</span> de <span>fidélité</span> ?</h3>

                    <div className="fidelity-why-container">
                        <div className="fidelity-why-content">
                            <h4>Pour votre point de vente</h4>
                            <p><strong>L'outil indispensable pour booster vos ventes !</strong></p>

                            <ul>
                                <li><strong>Augmenter</strong> votre chiffre d'affaires</li>
                                <li><strong>Faire revenir</strong> vos clients plus souvent, les <strong>fidéliser</strong></li>
                                <li>Renforcer votre <strong>IMAGE DE MARQUE</strong></li>
                                <li>Constituer un <strong>FICHIER CLIENTS</strong> de qualité</li>
                            </ul>

                            <p className="info">et de valoriser votre fond de commerce</p>
                        </div>

                        <div className="fidelity-why-content">
                            <h4>Pour votre client</h4>
                            <ul>
                                <li><strong>Récompenser</strong> sa fidélité</li>
                                <li><strong>Être reconnu</strong> et <strong>informé</strong></li>
                                <li><strong>Être contacté</strong> facilement et plus souvent</li>
                                <li><strong>L'informer</strong> des bons plans</li>
                            </ul>
                        </div>
                    </div>

                    <div className="info-box">
                        C'est un <strong>outil marketing</strong> qui permet:
                        <ul>
                            <li>de <strong>valoriser et considérer</strong> vos clients</li>
                            <li>d'<strong>augmenter la fréquentation du magasin</strong> et votre <strong>chiffre d'affaires</strong></li>
                        </ul>
                    </div>
                </section>

                <section>
                    <h3 className="fidelity-title"><span>Témoignages</span> de <span>Magasins</span></h3>
                    <div className="video-container">
                        <div className="video-iframe">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/5ZLzxZIxiLI?si=9h3uawAUJ0eByBkB" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            <p className="video-legende">Témoignage de Gabriel Homery du magasin Bornand à Bourg Saint Maurice</p>
                        </div>
                        <div className="video-iframe">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/hNAktP6rBC0?si=HWqEPzPIiSxSpwTt" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            <p className="video-legende">Témoignage de Ronan Le Henanff de la jardinerie Porh Mirabeau à Pluvigner</p>
                        </div>
                    </div>
                </section>

                <section>
                    <h3 className="fidelity-title">Le <span>Fonctionnement</span> du <span>programme</span> de <span>fidélité</span></h3>

                    <div className="fidelity-operating-container">


                        <div className="fidelity-operating-content">
                            {magasin && magasin.ens_code === "FRU" && <img width="100%" src={blocFidLISA1} alt="bloc fid Lisa 1" />}
                            {magasin && magasin.ens_code === "CDS" && <img width="100%" src={blocFidCDS1} alt="bloc fid CDS 1" />}
                            {magasin && magasin.ens_code === "TN" && <img width="100%" src={blocFidTN1} alt="bloc fid TN 1" />}
                            {magasin && magasin.ens_code === "CAP" && <img width="100%" src={blocFidLISA1} alt="bloc fid Lisa 1" />}
                        </div>
                        <div className="fidelity-operating-content">
                            {magasin && magasin.ens_code === "FRU" && <img width="100%" src={blocFidLISA2} alt="bloc fid Lisa 2" />}
                            {magasin && magasin.ens_code === "CDS" && <img width="100%" src={blocFidCDS2} alt="bloc fid CDS 2" />}
                            {magasin && magasin.ens_code === "TN" && <img width="100%" src={blocFidTN2} alt="bloc fid TN 2" />}
                            {magasin && magasin.ens_code === "CAP" && <img width="100%" src={blocFidLISA2} alt="bloc fid Lisa 2" />}
                        </div>
                    </div>
                </section>

                <div className="more-button-container">
                    {
                        magasin.ens_code !== "TN" ?
                            <button className="more-button" onClick={() => setPopupHide(!popupHide)} >En savoir plus</button>
                            :
                            <div>
                                <button className="more-button"
                                    onClick={() => {
                                        setPopupType('PT')
                                        setPopupHide(!popupHide)
                                    }} >Programme chasse En savoir plus</button>
                                <button className="more-button"
                                    onClick={() => {
                                        setPopupType('PP')
                                        setPopupHide(!popupHide)
                                    }} >Programme pêche En savoir plus</button>
                            </div>
                    }

                    {
                        popupHide && <PopUpIframe
                            close={() => setPopupHide(!popupHide)}
                            ens_code={magasin.ens_code}
                            type={popupType}
                        />
                    }
                </div>

            </FideliteLayout>
        </div>

    )
}

const PopUpIframe = ({ close, ens_code, type }) => {

    useEffect(() => {
        document.body.style.overflow = 'hidden';  // Désactive le scroll

        return () => {
            document.body.style.overflow = '';   // Réactive le scroll quand la popup se ferme
        };
    }, []);

    return (
        <div className="fid-popup-container">
            <button onClick={close} className="close-btn">X</button>
            {
                ens_code && ens_code === "FRU" && (<iframe allowfullscreen="allowfullscreen" title="France Rurale" scrolling="no" style={{ width: "80%", height: "100dvh" }} className="fp-iframe" src="
                    https://heyzine.com/flip-book/6511d04341.html"></iframe>)
            }
             {
                ens_code && ens_code === "CAP" && (<iframe allowfullscreen="allowfullscreen" title="France Rurale" scrolling="no" style={{ width: "80%", height: "100dvh" }} className="fp-iframe" src="
                    https://heyzine.com/flip-book/6511d04341.html"></iframe>)
            }
            {
                ens_code && ens_code === "CDS" && (<iframe allowfullscreen="allowfullscreen" title="Compagnons des saisons" scrolling="no" className="fp-iframe" style={{ width: "80%", height: "100dvh" }} src="
                    https://heyzine.com/flip-book/a3d0f75af2.html"></iframe>)
            }
            {
                ens_code && ens_code === "TN" && type === "PT" && (<iframe allowfullscreen="allowfullscreen" title="Territoires Nature - Pro Tracker" scrolling="no" className="fp-iframe" style={{ width: "80%", height: "100dvh" }} src="
                    https://heyzine.com/flip-book/870970f251.html"></iframe>)
            }
            {
                ens_code && ens_code === "TN" && type === "PP" && (<iframe allowfullscreen="allowfullscreen" title="Territoires Nature - Pro Pêche" scrolling="no" className="fp-iframe" style={{ width: "80%", height: "100dvh" }} src="
                    https://heyzine.com/flip-book/fc7da0b474.html"></iframe>)
            }
        </div>
    )
}

export default Fidelite;
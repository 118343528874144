import "./style.scss"

const FormBox = ({title, size="large" ,children}) => {
    return (
        <div className={`block ${size}`}>
            <h3>{title}</h3>

            <div>
                {children}
            </div>
        </div>
    )
}

export default FormBox;
import React from "react";
import "./style.scss";
import { DOMAIN } from "../../../../config/env";

const BDCprintCommandButton = ({ fournisseur }) => {
  return (
    <>
      <a
        className="pdf-command-btn"
        href={`${DOMAIN.URL_WEB}command-pdf/${fournisseur.bi_code}`}
        target="_blank"
        rel="noreferrer"
      >
        bon&nbsp;de&nbsp;commande
      </a>
    </>
  );
};

export default BDCprintCommandButton;

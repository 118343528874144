import React, { useState } from "react";
import "./style.scss";

const EngagementModal = ({ isOpen, onClose, onConfirm }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    comment: "",
    acceptTerms: false,
  });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.name || !formData.email || !formData.acceptTerms) {
      setError("Tous les champs sont obligatoires.");
      return;
    }
    setError("");
    setIsLoading(true);

    try {
      await onConfirm(formData);
    } catch (error) {
      console.error("Erreur lors de la soumission du formulaire", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="confirmation-modal-overlay">
      <div className="confirmation-modal-content">
        <div className="confirmation-modal-header">
          <div className="confirmation-modal-title">
            <h3>Valider l'engagement</h3>
          </div>
          <button className="confirmation-close-button" onClick={onClose}>
            ×
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="confirmation-modal-body">
            <div className="floating-label">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder=" "
              />
              <label>Nom</label>
            </div>
            <div className="floating-label">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder=" "
              />
              <label>Email</label>
            </div>
            <div className="floating-label">
              <textarea
                name="comment"
                value={formData.comment}
                onChange={handleChange}
                placeholder=" "
              />
              <label>Commentaire</label>
            </div>
            <label>
              <input
                type="checkbox"
                name="acceptTerms"
                checked={formData.acceptTerms}
                onChange={handleChange}
              />{" "}
              J'accepte les CGV{" "}
              <a
                href="https://www.agence-is-com.fr/wp-content/uploads/2024/02/CGV-IS-communication-Mars-2023.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                (Lien CGV)
              </a>
            </label>
            {error && <p className="error-message">{error}</p>}
          </div>
          <div className="confirmation-modal-footer">
            <button
              type="button"
              className="confirmation-cancel-button"
              onClick={onClose}
            >
              Annuler
            </button>
            <button
              type="submit"
              className="confirmation-add-button"
              disabled={isLoading}
            >
              {isLoading && <div className="spinner"></div>}
              {isLoading ? "" : "Valider"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EngagementModal;

import React from "react";
import { FaExclamationTriangle, FaChevronRight } from "react-icons/fa";
import "./style.scss";

const ErrorBanner = ({ errors }) => {
  if (!errors || errors.length === 0) return null;

  return (
    <div className="operation-error-banner">
      <div className="operation-error-banner-header">
        <FaExclamationTriangle size={24} color="#ff9800" className="icon" />
        <span>
          Attention des erreurs ont été trouvées, merci de rectifier afin de
          pouvoir enregistrer votre opération.
        </span>
      </div>
      <ul>
        {errors.map((error, index) => (
          <li key={index}>
            <FaChevronRight size={12} color="black" className="list-icon" />
            {error.message}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ErrorBanner;

import React, { useCallback, useState, useMemo } from 'react';
import './style.scss';
import useAuth from '../../../../../hooks/useAuth';
import { getPlanosByMetier } from '../../../../../services/fournisseurs_produits_apis';
import { DOMAIN } from '../../../../../config/env';
import { PATH, RESSOURCE } from '../../../../../config/constants';
import ReturnBtn from '../../../../../components/ui/button/ReturnBtn';
import NormalTable from './../../../../../components/ui/tables/NormalTable';
import Loader from '../../../../../components/ui/loader/Loader';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Planos = () => {
  const { magasin } = useAuth();
  const { idMetier } = useSelector((state) => state.metier);

  const [planosList, setPlanosList] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchPlanos = useCallback(async () => {
    try {
      const data = await getPlanosByMetier(
        magasin.mag_code,
        idMetier
      );
      setPlanosList(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }, [idMetier, magasin.mag_code]);

  React.useEffect(() => {
    fetchPlanos();
  }, [fetchPlanos]);

  const columns = useMemo(
    () => [
      {
        Header: 'Libellé Plano Gammes',
        accessor: 'pga_lib',
        filter: 'includes',
      },
      {
        Header: 'Chef Produits',
        accessor: 'che_nom',
        filter: 'includes',
      },
      {
        Header: 'Photos',
        accessor: 'pga_photo',
        Cell: ({ row }) => {
          const photoUrl = 'P' + row.original.pga_code;
          return row.original.pga_photo.length > 2 ? (
            <a
              href={
                DOMAIN.URL +
                '/' +
                RESSOURCE.PHOTO_PLANOS +
                photoUrl +
                '.jpg'
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              Photo
            </a>
          ) : (
            <span></span>
          );
        },
      },
      {
        Header: 'Rayon',
        Cell: ({ row }) => {
          const { ray_lib, fam_lib } = row.original;
          return `${ray_lib} - ${fam_lib}`;
        },
      },
      {
        Header: ' - ',
        accessor: 'pga_code',
        Cell: ({ row }) => {
          return (
            <Link
              to={PATH.PLANOS_DETAILS.replace(
                ':idMetier',
                idMetier
              ).replace(':pga_code', row.original.pga_code)}
            >
              Voir
            </Link>
          );
        },
      },
    ],
    [idMetier]
  );

  if (loading)
    return (
      //   <FournisseursProduitsLayout>
      <Loader />
      //   </FournisseursProduitsLayout>
    );

  return (
    <div className="planos">
      <h3 className="planos-title">Planos</h3>

      <ReturnBtn />

      <NormalTable columns={columns} data={planosList} />
    </div>
  );
};

export default Planos;

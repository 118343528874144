import React from "react";
import PageBannerLayout from '../../PageBannerLayout';
import banniere from './banniere_univers_les_inedits.png';

const MdgLayout = ({children}) =>{
    return (
        <PageBannerLayout imageHeader={banniere}>
            {children}
        </PageBannerLayout>
    );
};
export default MdgLayout;
const FactoryIcon = ({ height=20, width=20}) => {
    return <svg 
    fill="currentColor" 
    width={height}
    height={width}
     viewBox="0 0 24 24" 
     xmlns="http://www.w3.org/2000/svg">
        <path d="M22,2V5H18V2ZM18,7h4V22H2V10L8,7v3l6-3v3h4ZM8,17H5v2H8Zm0-4H5v2H8Zm6,4H10v2h4Zm0-4H10v2h4Zm5,4H16v2h3Zm0-4H16v2h3Z"/>
    </svg>
}

export default FactoryIcon;
import FournisseursProduitsLayout from "../../../components/layout/_fournisseurs_produits/FournisseursProduitsLayout";
import "./style.scss";
import { DOMAIN } from "../../../config/env";

const Solutions = () => {
  return (
    <FournisseursProduitsLayout>
      <div className="solution">
        <h3 className="solution-title">Solutions Logistiques</h3>
        <div className="solution-content">
          <div className="solution-map">
            <a
              href={`${DOMAIN.URL}/_fichier/_solutions/2024/Guides%20solu%20logistique%202024%20PEA.pdf`}
              target="_blank"
              rel="noreferrer"
            >
              Guide des solutions PEA
            </a>{" "}
            Franco 800€
            <img
              className="solution-image"
              src={`${DOMAIN.URL}/_fichier/_solutions/2024/pea.jpg`}
              alt=""
            />
          </div>
          <div className="solution-map">
            <a
              href={`${DOMAIN.URL}/_fichier/_solutions/2024/Guides%20solu%20logistique%202024%20LEDUC.pdf`}
            >
              Guide des solutions LEDUC LUBOT
            </a>{" "}
            Franco 500€
            <img
              className="solution-image"
              src={`${DOMAIN.URL}/_fichier/_solutions/2024/leduc.jpg`}
              alt=""
            />
          </div>
        </div>
      </div>
    </FournisseursProduitsLayout>
  );
};

export default Solutions;

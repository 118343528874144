import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import useAuth from '../../../../../hooks/useAuth';
import { CLsendDevisDocSpe } from '../../../../../services/communication_apis';
import { formatDate, TODAY_DATE } from '../../../../../utils/dates';
import Input from '../../../input/Input';
import Select from '../../../input/Select';
import Textarea from '../../../input/Textarea';
import CLdevisModal from '../../../modal/communication/CLdevisModal';
import './style.scss';
import validation from './validation';

const CLdocSpeForm = () => {
  const [isValidForm, setIsValidForm] = useState(false);
  const [errors, setErrors] = useState({});
  const [hasZoneDistri, setHasZoneDistri] = useState(true);
  const { zoneDistri } = useOutletContext();
  const { user, magasin } = useAuth();

  const whiteData = {
    email: user.email,
    mag_nom:magasin.mag_nom,
    mag_ville:magasin.mag_ville,
    type_doc: 1, // Type de document
    date_valid_deb: TODAY_DATE,
    date_valid_fin: TODAY_DATE,
    distri: 1,
    cmz_code: -1,
    com: '',
    qte_caisse: 50,
    qte_distri: '',
    zone_distri: '',
  };
  const [data, setData] = useState(whiteData);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeDistri = (event) => {
    const value = parseInt(event.target.value);
    setHasZoneDistri(value !== 0);
  };

  const handleSubmit = (event) => {
    // Validation check for inputs
    const validate = async () => {
      const errorBag = await validation(data);
      setErrors(errorBag);
      const hasErrors = Object.keys(errorBag).length;
      if (hasErrors) {
        throw new Error('Champs invalides');
      }
      setIsValidForm(!hasErrors);
    };
    try {
      event.preventDefault();
      setIsValidForm(false);
      validate();
    } catch (err) {
      console.log(err);
    }
  };

  // Send mail devis on validation
  const handleSendDevis = async () => {
    const el1 = document.getElementById('cmz_code');
    const el2 = document.getElementById('type_doc');

    let d = {
      ...data,
      date_valid_deb: formatDate(data.date_valid_deb),
      date_valid_fin: formatDate(data.date_valid_fin),
      zone_distri: el1.options[el1.selectedIndex].text,
      type_doc: el2.options[el2.selectedIndex].text,
      distri: !!data.distri ? 'oui' : 'non',
    };
    await CLsendDevisDocSpe(d);
    setHasZoneDistri(true);
    setData(whiteData);
  };

  return (
    <form onSubmit={(data) => handleSubmit(data)} className="cl-doc">
      <Select
        label="Type de document"
        name="type_doc"
        id="type_doc"
        value={data.type_doc}
        onChange={(event) => handleChange(event)}
        error={errors.type_doc}
      >
        <option value={1}>A4 recto / verso</option>
        <option value={2}>A4 4 pages</option>
        <option value={3}>A4 6 pages</option>
        <option value={4}>A4 8 pages</option>
        <option value={5}>Tabloïd recto / verso</option>
        <option value={6}>Tabloïd 4 pages</option>
        <option value={7}>A5 recto seul</option>
        <option value={8}>A5 recto / verso</option>
      </Select>
      <div className="date-validite">
        <Input
          type="date"
          min={TODAY_DATE}
          label="Début date de validité"
          name="date_valid_deb"
          value={data.date_valid_deb}
          onChange={(event) => handleChange(event)}
          error={errors.date_valid_deb}
        />
        <Input
          type="date"
          min={data.date_valid_deb}
          label="Fin date de validité"
          name="date_valid_fin"
          value={data.date_valid_fin}
          onChange={(event) => handleChange(event)}
          error={errors.date_valid_fin}
        />
      </div>
      <Select
        label="Distribution"
        name="distri"
        value={data.distri}
        onChange={(event) => handleChange(event)}
        error={errors.distri}
      >
        <option value={1}>Oui</option>
        <option value={2}>Non</option>
      </Select>

      <div className="justify">
        <Select
          label="Zone de distribution sur extranet"
          name="cmz_code"
          id="cmz_code"
          value={data.cmz_code}
          onChange={(event) => {
            handleChange(event);
            handleChangeDistri(event);
          }}
          error={errors.cmz_code}
        >
          <option value={-1}> -- Selectionner -- </option>
          <option value={0}> Aucune </option>
          {zoneDistri.length &&
            zoneDistri.map((zone) => (
              <option key={zone.id_mag_zone} value={zone.id_mag_zone}>
                {zone.cmz_libelle}
              </option>
            ))}
        </Select>
        {!hasZoneDistri && (
          <Input
            label="Quantité de ditribution"
            name="qte_distri"
            placeholder="Saisir une quantité"
            value={data.qte_distri}
            onChange={(event) => handleChange(event)}
            error={errors.qte_distri}
          />
        )}
      </div>

      <Input
        label="Quantité exemplaire caisse"
        name="qte_caisse"
        placeholder="Saisir une quantité"
        value={data.qte_caisse}
        onChange={(event) => handleChange(event)}
        error={errors.qte_caisse}
      />
      <Input
        type="email"
        label="Email"
        name="email"
        value={data.email}
        onChange={(event) => handleChange(event)}
        error={errors.email}
      />
      <Textarea
        label="Commentaire"
        rows="6"
        maxLength="255"
        placeholder=" "
        name="com"
        value={data.com}
        onChange={(event) => handleChange(event)}
        error={errors.com}
      />
      <div className="cl-doc__action">
        <CLdevisModal
          isValidForm={isValidForm}
          onValidation={handleSendDevis}
          email={data.email}
        />
      </div>
    </form>
  );
};

export default CLdocSpeForm;

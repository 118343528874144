import React, { useState, useEffect, useContext } from "react";
import "./style.scss";
import ReturnBtn from "../../../../components/ui/button/ReturnBtn";
import {
  getMagOpeByMagOperation,
  createCommande,
  deleteMagOpe,
  getRepiquageByMag,
} from "../../../../services/engagement_apis";
import useAuth from "../../../../hooks/useAuth";
import PendingOperations from "./PendingOperations";
import ConfirmedOperations from "./ConfirmedOperations";
import EngagementModal from "./engagementModal";
import ConfirmationModal from "../../../../components/ui/modal/confirmationModal";
import { formatDate } from "../../../../utils/dates";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "react-loading-skeleton/dist/skeleton.css";
import { CSSTransition } from "react-transition-group";
import SkeletonLoader from "./skeleton/SkeletonLoader";
import { YearContext } from "../../../../contexts/YearContext"; // Import the context

const MesEngagements = () => {
  const navigate = useNavigate();
  const { magasin } = useAuth();
  const currentYear = new Date().getFullYear();
  const { selectedYear, setSelectedYear } = useContext(YearContext);
  const [pendingData, setPendingData] = useState([]);
  const [confirmedData, setConfirmedData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDataVisible, setIsDataVisible] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isEngagementModalOpen, setIsEngagementModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [idStatut, setIdStatut] = useState(null);

  const fetchData = async (selectedYear) => {
    setIsLoading(true);
    setIsDataVisible(false);
    setPendingData([]);
    setConfirmedData([]);
    try {
      const response = await getMagOpeByMagOperation(
        magasin.mag_code,
        selectedYear
      );
      const formatData = (items) => {
        const today = moment();

        return items
          .map((item, index) => {
            let dates = "";
            let isExpired = false;

            if (item.mo_choix_date === 1) {
              dates = `${formatDate(item.operation.ope_datedeb1)} - ${formatDate(item.operation.ope_datefin1)}`;
            } else if (item.mo_choix_date === 2) {
              dates = `${formatDate(item.operation.ope_datedeb2)} - ${formatDate(item.operation.ope_datefin2)}`;
            } else if (item.mo_choix_date === 3) {
              dates = `${formatDate(item.mo_datedeb3)} - ${formatDate(item.mo_datefin3)}`;
            }

            if (moment(item.operation.ope_datefinenga).isBefore(today)) {
              isExpired = true;
            }

            return {
              id: item.id_operation,
              operation: item.operation.ope_libelle,
              mois: item.operation.mois.mo_libelle,
              datesValidité: dates,
              datesEngagement: `${formatDate(item.operation.ope_datedebutenga)} - ${formatDate(item.operation.ope_datefinenga)}`,
              cout: item.mo_mnt_total,
              isExpired: isExpired,
            };
          })
          .sort(
            (a, b) =>
              moment(a.mois, "MMMM").month() - moment(b.mois, "MMMM").month()
          );
      };

      setPendingData(formatData(response.pending));
      setConfirmedData(formatData(response.confirmed));

      const repiquageResponse = await getRepiquageByMag(magasin.mag_code);
      const selectedYearRepiquage =
        repiquageResponse[selectedYear][0].id_statut;
      setIdStatut(selectedYearRepiquage);
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(selectedYear);
  }, [magasin.mag_code, selectedYear]);

  useEffect(() => {
    if (!isLoading) {
      const timer = setTimeout(() => {
        setIsDataVisible(true);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [isLoading]);

  const calculateTotal = (data) => {
    return data.reduce((total, item) => total + item.cout, 0);
  };

  const handleDelete = (id) => {
    setRowToDelete(id);
    setIsConfirmationModalOpen(true);
  };

  const confirmDelete = async () => {
    const requestData = {
      mag_code: magasin.mag_code,
      id_operation: rowToDelete,
    };
    try {
      const response = await deleteMagOpe(requestData);
      if (response.status === 200) {
        const updatedData = pendingData.filter((row) => row.id !== rowToDelete);
        setPendingData(updatedData);
        setIsConfirmationModalOpen(false);
        setRowToDelete(null);
      } else {
        console.error(
          "Erreur lors de la suppression de l'opération:",
          response
        );
      }
    } catch (error) {
      console.error("Erreur lors de l'appel à l'API:", error);
    }
  };

  const handleSelectRow = (id) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId) => rowId !== id)
        : [...prevSelectedRows, id]
    );
  };

  const handleConfirmSelected = () => {
    if (selectedRows.length === 0) {
      setErrorMessage(
        "Veuillez sélectionnez au moins un plan de communication."
      );
      return;
    }
    setErrorMessage("");
    setIsEngagementModalOpen(true);
  };

  const handleModalConfirm = async (formData) => {
    const selectedOperations = pendingData.filter((row) =>
      selectedRows.includes(row.id)
    );

    const data = selectedOperations.reduce((acc, curr, index) => {
      acc[index] = { id_operation: curr.id };
      return acc;
    }, {});

    const requestData = {
      mag_code: magasin.mag_code,
      cmd_qui: formData.name,
      cmd_commentaire: formData.comment,
      cmd_email: formData.email,
      annee: selectedYear,
      data: data,
    };
    try {
      const response = await createCommande(requestData);
      if (response.status === 200) {
        setConfirmedData([...confirmedData, ...selectedOperations]);
        const updatedPendingData = pendingData.filter(
          (row) => !selectedRows.includes(row.id)
        );
        setPendingData(updatedPendingData);
        setSelectedRows([]);
      } else {
        console.error("Erreur lors de la création de la commande:", response);
      }
    } catch (error) {
      console.error("Erreur lors de l'appel à l'API:", error);
    }

    setIsEngagementModalOpen(false);
  };

  const handleUpdateOperation = (id_operation) => {
    navigate(`/cmNational/operation/${id_operation}/${selectedYear}`);
  };
  const handleViewDetails = (id) => {
    navigate(`/operation-details/${id}`);
  };

  return (
    <div>
      <div className="CmNationale-titre">LES ENGAGEMENTS</div>
      <ReturnBtn />
      <select
        value={selectedYear}
        onChange={(e) => setSelectedYear(parseInt(e.target.value))}
      >
        <option value={currentYear - 1}>{currentYear - 1}</option>
        <option value={currentYear}>{currentYear}</option>
        <option value={currentYear + 1}>{currentYear + 1}</option>
      </select>
      <CSSTransition
        in={isLoading}
        timeout={500}
        classNames="fade"
        unmountOnExit
      >
        <SkeletonLoader />
      </CSSTransition>
      <CSSTransition
        in={!isLoading && isDataVisible}
        timeout={500}
        classNames="fade"
        unmountOnExit
      >
        <div>
          {!isLoading && pendingData.length > 0 ? (
            <PendingOperations
              data={pendingData}
              handleSelectRow={handleSelectRow}
              selectedRows={selectedRows}
              handleDelete={handleDelete}
              handleConfirmSelected={handleConfirmSelected}
              errorMessage={errorMessage}
              total={calculateTotal(pendingData)}
              handleUpdateOperation={handleUpdateOperation}
              idStatut={idStatut}
            />
          ) : (
            !isLoading && (
              <div className="aucun-container">
                <p>Aucune plan de communication à valider</p>
              </div>
            )
          )}
          {!isLoading && confirmedData.length > 0 ? (
            <ConfirmedOperations
              data={confirmedData}
              onViewDetails={handleUpdateOperation}
            />
          ) : (
            !isLoading && (
              <div className="aucun-container">
                <p>Aucune plan de communication validé</p>
              </div>
            )
          )}
        </div>
      </CSSTransition>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        onConfirm={confirmDelete}
        message="Êtes-vous sûr de vouloir supprimer cette opération ?"
      />
      <EngagementModal
        isOpen={isEngagementModalOpen}
        onClose={() => setIsEngagementModalOpen(false)}
        onConfirm={handleModalConfirm}
      />
    </div>
  );
};

export default MesEngagements;

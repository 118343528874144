import React from 'react';

const PromotionIcon = ({ height = 20, width = 20 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" 
    class="icon icon-tabler icon-tabler-currency-euro" 
    height={height}
    width={width}
    viewBox="0 0 24 24" 
    stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M17.2 7a6 7 0 1 0 0 10" />
  <path d="M13 10h-8m0 4h8" />
</svg>
  );
};

export default PromotionIcon;

import React, { useEffect, useState, useContext } from "react";
import "./style.scss";
import ReturnBtn from "../../../../components/ui/button/ReturnBtn";
import { getOperationsByMagasin } from "../../../../services/engagement_apis";
import useAuth from "../../../../hooks/useAuth";
import { formatDate } from "../../../../utils/dates";
import { useNavigate } from "react-router-dom";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { YearContext } from "../../../../contexts/YearContext"; // Import the context

const EngaCom = () => {
  const navigate = useNavigate();
  const { magasin } = useAuth();
  const [operationsData, setOperationsData] = useState({});
  const { selectedYear, setSelectedYear } = useContext(YearContext);

  useEffect(() => {
    const loadData = async () => {
      try {
        const operationsResponse = await getOperationsByMagasin(
          magasin.mag_code
        );
        const operations = operationsResponse;
        setOperationsData(operations);

        if (
          !operations[selectedYear] ||
          operations[selectedYear].length === 0
        ) {
          const availableYears = Object.keys(operations);
          const nextYearWithOperations = availableYears.find(
            (year) => operations[year].length > 0
          );
          if (nextYearWithOperations) {
            setSelectedYear(nextYearWithOperations);
          }
        }
      } catch (error) {
        console.error("Could not fetch data from API", error);
      }
    };
    loadData();
  }, [magasin.mag_code]);

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };
  const handleRowClick = (id_operation) => {
    navigate(`/cmNational/operation/${id_operation}/${selectedYear}`);
  };

  const handleBonDeCommandeClick = (e, psp_cprospectus) => {
    e.stopPropagation();
    navigate(`/bdc/prospectus/${psp_cprospectus}`);
  };

  const operations = operationsData[selectedYear] || [];

  const months = Array.from(
    new Set(operations.map((op) => op.mois.mo_libelle))
  );

  const groupedOperations = months.map((month) => ({
    month,
    operations: operations.filter(
      (operation) => operation.mois.mo_libelle === month
    ),
  }));

  return (
    <div>
      <div className="CmNationale-titre">LES OPERATIONS</div>
      <ReturnBtn />
      <div>
        <div className="table-container">
          <div className="year-selector">
            <label htmlFor="year">Année : </label>
            <select id="year" value={selectedYear} onChange={handleYearChange}>
              {Object.keys(operationsData).map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
          <table className="table-engagement">
            <thead>
              <tr>
                <th>OPÉRATION</th>
                <th>DATE DE VALIDITÉ</th>
                <th>FIN D'ENGAGEMENT</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {groupedOperations.map((monthData, index) => (
                <React.Fragment key={index}>
                  {monthData.operations.length > 0 && (
                    <tr className="month-row">
                      <td colSpan="4">{monthData.month}</td>{" "}
                    </tr>
                  )}
                  {monthData.operations.map((operation, opIndex) => (
                    <tr
                      key={opIndex}
                      className="operation-row"
                      onClick={() => handleRowClick(operation.id_operation)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{operation.ope_libelle}</td>
                      <td className="center-text">
                        {operation.ope_datedeb1 &&
                        !isNaN(new Date(operation.ope_datedeb1)) &&
                        operation.ope_datefin1 &&
                        !isNaN(new Date(operation.ope_datefin1))
                          ? `Du ${formatDate(operation.ope_datedeb1)} Au ${formatDate(operation.ope_datefin1)}`
                          : "-"}
                      </td>
                      <td className="center-text">
                        {operation.ope_datefinenga &&
                        !isNaN(new Date(operation.ope_datefinenga))
                          ? formatDate(operation.ope_datefinenga)
                          : "-"}
                      </td>
                      <td className="center-text">
                        {operation.psp_cprospectus !== 0 && (
                          <button
                            className="bon-de-commande-btn"
                            onClick={(e) =>
                              handleBonDeCommandeClick(
                                e,
                                operation.psp_cprospectus
                              )
                            }
                          >
                            <TextSnippetIcon />
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default EngaCom;

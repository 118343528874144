import React, { useState } from "react";
import { DOMAIN } from "../../../../../config/env";
import { RESSOURCE } from "../../../../../config/constants";
const Valider = ({ data, year }) => {

  const [zoomImage, setZoomImage] = useState(false);

  return (
    <div className="valider-container">
      <img
        src={`${DOMAIN.URL_WEB}${RESSOURCE.PHOTO_REPIQUAGE}${data.mr_fichier}`}
        alt=""
        className={`${zoomImage ? 'repiquage-image-zoom' : 'repiquage-image'}`}
        onClick={(e) => setZoomImage(!zoomImage)}
      />
      <p>Votre repiquage a bien été validé pour l'année {year}.</p>
      <p>
        Pour toute modification, veuillez passer par votre interlocuteur Inedis
        habituel
      </p>
    </div>
  );
};

export default Valider;

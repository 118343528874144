

export const DOMAIN = {

    /// CONFIG DEVELOPPEMENT

   //  URL: 'https://origine.inedis.fr',

   //  URL_API: 'http://127.0.0.1:8000/api/',

   //  URL_WEB: 'http://127.0.0.1:8000/',

  //  URL_APP: 'http://127.0.0.1:3000/',

//config de production


   URL: 'https://origine.inedis.fr/',

    URL_API: 'https://api.inedis.fr/api/',

    URL_WEB: 'https://api.inedis.fr/',

     URL_APP: 'https://extranet.inedis.fr/',

};



export const STATUS = {

    MAINTENANCE: false,

};

import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import AuthContext from "./AuthContext";
import { userAuth, userLogin, userLogout } from "../../services/auth_apis";
import { PATH, REDIRECT_LOGIN } from "../../config/constants";
import Loader from "../../components/ui/loader/Loader";
import { counterMagasinConnexion } from "../../services/apis";

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [authData, setAuthData] = useState({
    loading: true, // Indique si les données chargent ou non
    user: {}, // User authentifié
    magasin: {}, // Magasin sélectionné pour naviguer
    authed: false, // Indicateur d'authentification
  });

  const { user, authed, loading, magasin } = authData;

  const handleMagasin = (magasin) =>
    setAuthData({ ...authData, magasin: magasin });

  const handleSelectionMagasin = () => {
    let redirect = location.state?.from?.pathname || REDIRECT_LOGIN;
    switch (user.magasins.length) {
      // Aucun magasin associé à l'utilisateur
      case 0:
        console.log("pas de magasins");
        break;
      // Un seul magasin associé à l'utilisateur
      case 1:
        setAuthData({
          ...authData,
          magasin: user.magasins[0],
        });

        break;
      // Plusieurs magasins associés à l'utilisateur
      default:
        redirect = PATH.SELECT_MAGASIN;
    }
    navigate(redirect);
  }; // Vérification du nombre de magasins associés à l'utilisateur actif

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        // Récupération de l'emplacement de redirection à partir de l'état de l'emplacement actuel
        let redirect = location.state?.from?.pathname;

        // Vérification de l'authentification de l'utilisateur actif
        const activeUser = await userAuth();

        // Copie des données d'authentification existantes avec l'indicateur de chargement mis à faux
        let updatedAuthData = { ...authData, loading: false };

        if (activeUser) {
          // Mise à jour des données d'authentification avec l'utilisateur actif et le statut d'authentification
          updatedAuthData = {
            ...updatedAuthData,
            user: activeUser,
            authed: true,
          };

          // Vérification du nombre de magasins associés à l'utilisateur actif
          switch (activeUser.magasins.length) {
            // Aucun magasin associé à l'utilisateur
            case 0:
              redirect = PATH.HOME_PAGE;
              break;
            // Un seul magasin associé à l'utilisateur
            case 1:
              updatedAuthData = {
                ...updatedAuthData,
                magasin: activeUser.magasins[0],
              };
              break;
            // Plusieurs magasins associés à l'utilisateur
            default:
              redirect = PATH.SELECT_MAGASIN;
          }
        }
        setAuthData(updatedAuthData);
        navigate(redirect);
      } catch (err) {
        // Retour a la page de login
        setAuthData({ ...authData, loading: false });
        console.log(err);
      }
    };
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // login function
  const handleLogin = async (loginCredentials) => {
    let redirect = location.state?.from?.pathname || REDIRECT_LOGIN;

    const lastLoginDate = new Date().toISOString();
    const loginData = { ...loginCredentials, lastLogin: lastLoginDate };

    const userLogged = await userLogin(loginData);

    let updatedAuthData = { ...authData };
    if (userLogged) {
      updatedAuthData = {
        ...authData,
        user: userLogged,
        authed: true,
      };

      // Redirige vers la page de changement de mot de passe si le mot de passe n'a pas été changé
      if (userLogged.password_reset) {
        redirect = PATH.CHANGE_PASSWORD.replace(
          ":username",
          userLogged.username
        );
      } else {
        switch (userLogged.magasins.length) {
          // Aucun magasin associé à l'utilisateur
          case 0:
            redirect = PATH.HOME_PAGE;
            break;
          // Un seul magasin associé à l'utilisateur
          case 1:
            updatedAuthData = {
              ...updatedAuthData,
              magasin: userLogged.magasins[0],
            };
            // Augmente l'indicateur du nombre de connexion
            counterMagasinConnexion(userLogged.magasins[0].mag_code);
            break;
          // Plusieurs magasins associés à l'utilisateur
          default:
            redirect = PATH.SELECT_MAGASIN;
        }
      }
    }
    setAuthData(updatedAuthData);
    navigate(redirect);
  };

  // logout fonction
  const handleLogout = async () => {
    if (authed) {
      await userLogout();
      setAuthData({ ...authData, user: {}, authed: false });
    }

    navigate(PATH.LOGIN_PAGE);
  };

  // Data share with context
  const value = {
    user,
    loading,
    authed,
    setAuthData,
    authData,
    magasin,
    changeMagasin: handleMagasin,
    onLogin: handleLogin,
    onLogout: handleLogout,
    selectionMagasin: handleSelectionMagasin,
  };

  if (loading) return <Loader />;

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

import React, { useState } from "react";
import OutlinedLink from "../../links/OutlinedLink";
import FilledLink from "../../links/FilledLink";
import ExternalLink from "../../links/ExternalLink";
import DrawerToggleButton from "../../button/DrawerToggleButton";
import "./style.scss";

const linkComponents = {
  outlined: OutlinedLink,
  filled: FilledLink,
};

const Drawer = ({ links }) => {
  const [hideContent, setHideContent] = useState(
    window.innerWidth <= 600 ? true : false
  );

  React.useEffect(() => console.log(window.innerWidth), []);

  const handleToggle = () => setHideContent((state) => !state);

  return (
    <div className="drawer-overlay">
      <div className={`drawer ${!hideContent ? "drawer--open" : ""}`}>
        <div className="drawer__head">
          <DrawerToggleButton
            toggle={handleToggle}
            openDefault={!hideContent}
          />
        </div>
        {!hideContent && (
          <div className="drawer__content">
            {links.map((link, i) => {
              if (link.href) {
                return (
                  <ExternalLink
                    key={"drawer-link-" + i}
                    href={link.href}
                    icon={link.icon}
                    className={link.class}
                    libelle={link.libelle}
                    sublibelle={link.sublibelle}
                    styleType={link.type}
                  />
                );
              }

              const LinkComponent = linkComponents[link.type] || OutlinedLink;
              return (
                <LinkComponent
                  key={"drawer-link-" + i}
                  to={link.to}
                  icon={link.icon}
                  className={link.class}
                  libelle={link.libelle}
                  sublibelle={link.sublibelle}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Drawer;

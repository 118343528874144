import React, { useCallback, useState } from 'react';
import useAuth from '../../../../../hooks/useAuth';
import { useParams } from 'react-router-dom';
import { getContratDetail } from '../../../../../services/fournisseurs_produits_apis';
import { DOMAIN } from '../../../../../config/env';
import { RESSOURCE } from '../../../../../config/constants';
import './style.scss';
import ClassificationFournisseurTable from '../../../../../components/ui/tables/_fournisseurs_produits/ClassificationFournisseurTable';
import PlanoFournisseurTable from '../../../../../components/ui/tables/_fournisseurs_produits/PlanoFournisseurTable';
import ReturnBtn from '../../../../../components/ui/button/ReturnBtn';
import StarIcon from '../../../../../components/ui/icons/StarIcon';
import Loader from '../../../../../components/ui/loader/Loader';

const ContratDetail = () => {
  const { magasin } = useAuth();
  const con_code = parseInt(useParams().con_code);

  //   const
  const [loading, setLoading] = useState({});
  const [contratDetail, setContratDetail] = useState([]);

  const fetchAPI = useCallback(async () => {
    try {
      const data = await getContratDetail(magasin.mag_code, con_code);
      setContratDetail(data);
    } catch (err) {
      console.log(err);
    }
  }, [con_code, magasin.mag_code]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchAPI();
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [fetchAPI]);

  if (loading) return <Loader/>
  if (!contratDetail)
    return <div>
      <ReturnBtn />
      <p>Pas de détails disponibles sur le contrat.</p>
    </div>
  return (
    <div className="contrat-detail">
      <ReturnBtn />
      <h3 className="title">{contratDetail.con_nom}</h3>
      <div className="contrat-detail__content">
        <section className="contrat_detail__fournisseur section-wrap">
          <ul className="contrat-detail-info">
            <li>Numero de contrat : {contratDetail.con_code}</li>
            <li>Chef Produits : {contratDetail.che_nom}</li>
            <li>
              Nombre d'articles référencés : {contratDetail.con_nbart}
            </li>
            <li>
              <a
                href={`${DOMAIN.URL}/${RESSOURCE.CONTRATS_FICHES}F_${con_code}.PDF`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Fiche fournisseur PDF
              </a>
            </li>
            {!!contratDetail.logo && (
              <li>
                {console.log(contratDetail)}
                <img
                  src={`${DOMAIN.URL}/${RESSOURCE.PHOTO_FOURNISSEUR}${contratDetail.logo.con_image}`}
                  alt="four"
                />
              </li>
            )}
          </ul>
          {!!contratDetail.chronos.length && (
            <ul className="contrat-detail-chrono">
              <li className="sub-title"> Info et Promo chrono</li>
              {contratDetail.chronos.map((chrono) => (
                <li>
                  <a
                    href={
                      DOMAIN.URL +
                      '/' +
                      RESSOURCE.CHRONO +
                      chrono.ch_fichier
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    {chrono.ch_titre}
                  </a>
                </li>
              ))}
            </ul>
          )}
        </section>
        <div className="legend">
          LEADER&nbsp;:&nbsp;
          <span className="stars">
            <StarIcon />
            <StarIcon />
            <StarIcon />
          </span>
          &nbsp;|&nbsp;CHALLENGER&nbsp;:&nbsp;
          <span className="stars">
            <StarIcon />
            <StarIcon />
          </span>
          &nbsp;|&nbsp;COMPLEMENTAIRE&nbsp;:&nbsp;
          <span className="stars">
            <StarIcon />
          </span>
        </div>
        { contratDetail.classifications && contratDetail.classifications.length > 0 &&
        <section className="contrat_detail__classification section-wrap">
          <h4 className="section-title"> Classement par Famille</h4>
            <ClassificationFournisseurTable
            classifications={contratDetail.classifications}
          />
        </section>
        }
        {
          contratDetail.planos && contratDetail.planos.length > 0 &&
          <section className="contrat_detail__plano section-wrap">
            <h4 className="section-title">Présence plano gamme</h4>
            <PlanoFournisseurTable planos={contratDetail.planos} />
          </section>
        }
      </div>
    </div>
  );
};

export default ContratDetail;

import { DOMAIN } from '../config/env';
import api from './apiClient';

/**
 * return all fidelity packs
 */
export async function getFidelisationPacks () {
    try {
        const response = await api.get(
            DOMAIN.URL_API + 'cx/fidelisationPacks'
        );
        return response.data;

    } catch (error) {
        console.error("Failed to fetch fidelisations packs:", error);
        throw error.response ? error.response.data : new Error("An error occured")
    }
}

/**
 * return fidelity data for store
 * @param magcode
 */
export async function getFidelisationByMagcode (magcode) {
    try {
        const response = await api.get(
            DOMAIN.URL_API + 'cx/fidelisation/magcode/' + magcode
        );
        return response.data;
    } catch (error) {
        console.error("Failed to fetch fidelisation store by magcode:", error);
        throw error.response ? error.response.data : new Error("An error occured")
    }
}

/**
 * Save fidelity data
 */
export async function setFidelityData(data) {
    try {
        const response = await api.post(DOMAIN.URL_API + 'cx/saveFidelisation', data);
        return response.data
    } catch (error) {
        console.error("Failed to post fidelisation data", error);
        throw error.response ? error.response.data : new Error("An error occured")
    }
}

/**
 * Send email when fidelity form complete
 */
export async function sendEmailWhenFidelityFormComplete(data) {
    try {
        const response = await api.post(DOMAIN.URL_API + 'cx/emailFidelisation', data);
        return response.data
        
    } catch (error) {
        console.error("Failed to post fidelisation data", error);
        throw error.response ? error.response.data : new Error("An error occured")
    }
}
import React, { useMemo } from 'react';
import './style.scss';
import {
  setIdMetier,
  resetIdMetier,
} from '../../../../features/metierSlice';
import { useSelector, useDispatch } from 'react-redux';
import PhotoTextCard from '../../../../components/ui/cards/PhotoTextCard';
import { METIER, PATH } from '../../../../config/constants';
import photoImg from './option3.jpg';
import { importImages } from '../../../../utils/image';

const UPselectionMetier = () => {
  const dispatch = useDispatch();
  const { metiers } = useSelector((state) => state.metier);
  // Stock les images du dossiers images dans un tableau
  const images = useMemo(() => {
    const imagesContext = require.context('./images', false);
    return importImages(imagesContext);
  }, []);

  // Réinitialisation de idMetier sur la page de selection
  React.useEffect(() => {
    dispatch(resetIdMetier());
  }, [dispatch]);

  const handleCardClick = (id) => {
    dispatch(setIdMetier(id));
  };

  return (
    <div className="selection-metier">

      <div className="metier-cards">
        <div className="metier-card-wrap">
          <PhotoTextCard
              text={METIER.METIER_LIBELLE_ALL}
              onClick={() => handleCardClick(METIER.METIER_ID_ALL)}
              link={
                  PATH.UP_SELECTION_METIER + '/' + METIER.METIER_ID_ALL
              }
              photo={
                  images.find((image) =>
                      image.includes('metier_' + METIER.METIER_ID_ALL)
                  ) ?? photoImg
              }
          />
        </div>
        {metiers.map((metier) => (
          <div className="metier-card-wrap">
            <PhotoTextCard
              text={metier.met_lib}
              onClick={() => handleCardClick(metier.met_code)}
              link={PATH.UP_SELECTION_METIER + '/' + metier.met_code}
              photo={images.find((image) =>
                image.includes('metier_' + metier.met_code)
              )}
            />
          </div>
        ))}

      </div>
    </div>
  );
};

export default UPselectionMetier;

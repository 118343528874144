import React, { useState } from "react";
import "./style.scss";
import { useOutletContext } from "react-router-dom";
import useAuth from "../../../../../hooks/useAuth";
import { CLsendDevisSMSBDD } from "../../../../../services/communication_apis";
import { calculSMSbdd } from "../../../../../utils/CL_devis_calculs";
import { formatDate, TODAY_DATE } from "../../../../../utils/dates";
import Input from "../../../input/Input";
import Select from "../../../input/Select";
import Textarea from "../../../input/Textarea";
import CLdevisModal from "../../../modal/communication/CLdevisModal";
import validation from "./validation";

const CLsmsbddForm = () => {
  const [isValidForm, setIsValidForm] = useState(false);
  const [errors, setErrors] = useState({}); // Erreurs du formulaire
  const { potentiel } = useOutletContext(); // Pour le calcul du montant du devis

  const { user, magasin } = useAuth();

  const whiteData = {
    mag_nom: magasin.mag_nom,
    mag_ville: magasin.mag_ville,
    email: user.email,
    date_valid_deb: TODAY_DATE,
    date_valid_fin: TODAY_DATE,
    landing: 1,
    com: "",
    offre: "",
    ciblage: "",
    envoi_sms: TODAY_DATE,
    heure_sms: 12,
    expediteur: "",
    coordonnees: "",
  };
  const [data, setData] = useState(whiteData);

  const [tarification, setTarification] = useState({
    service: "SMS",
    frais_fixes: !!parseInt(data.landing) ? 190 : 30,
    cout_envoie: 0.25,
    minimum_commande: 2500,
  });

  React.useEffect(() => {
    if (potentiel) {
      setTarification({
        ...tarification,
        quantite: potentiel.mp_qte_sms,
        maj: potentiel.mp_date_maj,
        frais_fixes: !!parseInt(data.landing) ? 190 : 30,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.landing, potentiel]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validation check for inputs
    const validate = async () => {
      const errorBag = await validation(data);
      setErrors(errorBag);
      const hasErrors = Object.keys(errorBag).length;
      if (hasErrors) {
        throw new Error("Champs invalides");
      }
      setIsValidForm(!hasErrors);

      setTarification({
        ...tarification,
        montant: calculSMSbdd(tarification),
      });
    };
    try {
      setIsValidForm(false);
      validate();
    } catch (err) {
      console.log(err);
    }
  };

  // Send mail devis on validation
  const handleSendDevis = async () => {
    let d = {
      ...data,
      envoi_sms: formatDate(data.envoi_sms),
      date_valid_deb: formatDate(data.date_valid_deb),
      date_valid_fin: formatDate(data.date_valid_fin),
      tarification: tarification,
      landing: !!parseInt(data.landing) ? "oui" : "non",
    };
    await CLsendDevisSMSBDD(d);
    setData(whiteData);
  };

  return (
    <form onSubmit={(data) => handleSubmit(data)} className="cl-smsbdd">
      <Input
        label="Nom d'expediteur (maximum 11 caractères)"
        name="expediteur"
        value={data.expediteur}
        onChange={(event) => handleChange(event)}
        error={errors.expediteur}
        maxLength="11"
      />
      <Textarea
        label="Coordonnées"
        rows="2"
        maxLength="255"
        placeholder="Adresse, site web, reseaux sociaux, horaires d'ouverture ..."
        name="coordonnees"
        value={data.coordonnees}
        onChange={(event) => handleChange(event)}
        error={errors.coordonnees}
      />
      <div className="align-field">
        <Input
          type="date"
          min={TODAY_DATE}
          label="Date d'envoi du sms"
          name="envoi_sms"
          value={data.envoi_sms}
          onChange={(event) => handleChange(event)}
          error={errors.envoi_sms}
        />
        <Select
          label="Heure d'envoie du sms"
          name="heure_sms"
          value={data.heure_sms}
          onChange={(event) => handleChange(event)}
          error={errors.heure_sms}
        >
          {Array.from({ length: 24 }, (_, i) => i)
            .map((hour) => hour.toString().padStart(2, "0"))
            .map((hour) => (
              <option value={hour}>{hour}h</option>
            ))}
        </Select>
      </div>

      <div className="date-validite align-field">
        <Input
          type="date"
          min={TODAY_DATE}
          label="Début date de validité"
          name="date_valid_deb"
          value={data.date_valid_deb}
          onChange={(event) => handleChange(event)}
          error={errors.date_valid_deb}
        />
        <Input
          type="date"
          min={data.date_valid_deb}
          label="Fin date de validité"
          name="date_valid_fin"
          value={data.date_valid_fin}
          onChange={(event) => handleChange(event)}
          error={errors.date_valid_fin}
        />
      </div>
      <Textarea
        label="Offre"
        rows="2"
        maxLength="255"
        placeholder="Indiquez le texte souhaité."
        name="offre"
        value={data.offre}
        onChange={(event) => handleChange(event)}
        error={errors.offre}
      />
      <Select
        label="Landing page"
        name="landing"
        value={data.landing}
        onChange={(event) => handleChange(event)}
        error={errors.landing}
      >
        <option value={1}>Oui</option>
        <option value={0}>Non</option>
      </Select>
      <Textarea
        label="Ciblage"
        rows="3"
        maxLength="255"
        placeholder="Indiquez ici les codes postaux des communes souhaitées, si vous souhaitez ajouter une contrainte (genre, âge résidant en maison, ...)"
        name="ciblage"
        value={data.ciblage}
        onChange={(event) => handleChange(event)}
        error={errors.ciblage}
      />
      <div>
        <h4> Rappel des tarifs HT :</h4>
        <ul>
          <li>Frais fixes : {tarification.frais_fixes}&nbsp;€</li>
          <li>
            Envoi : {tarification.cout_envoie} € / {tarification.service}{" "}
            (minimum de commande {tarification.minimum_commande}{" "}
            {tarification.service})
          </li>
        </ul>
      </div>
      <Input
        type="email"
        label="Email"
        name="email"
        value={data.email}
        onChange={(event) => handleChange(event)}
        error={errors.email}
      />
      <Textarea
        label="Commentaire"
        rows="5"
        maxLength="255"
        placeholder=" "
        name="com"
        value={data.com}
        onChange={(event) => handleChange(event)}
        error={errors.com}
      />
      <div className="action-btn">
        <CLdevisModal
          isValidForm={isValidForm}
          onValidation={handleSendDevis}
          tarification={tarification}
          email={data.email}
          affichage={false}
        />
      </div>
    </form>
  );
};

export default CLsmsbddForm;

import { DOMAIN } from '../config/env';
import api from './apiClient';

/**
 * return all fidelity packs
 */
export async function saveCxStat (data) {
    try {
        const response = await api.post( DOMAIN.URL_API + 'stat/save', data);
        return response.data;

    } catch (error) {
        console.error("Failed to save stat", error);
        throw error.response ? error.response.data : new Error("An error occured")
    }
}